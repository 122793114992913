

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    background: var(--background-color);
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

.nft-container{
    max-width: 1300px;
    margin: 0 auto;
}



/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*=========main=========*/
.main {
  padding: 1rem 2rem;
}
/*============nft-section=================*/
.nft-content-wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 auto;
}

.nft-image {
  border-radius: 1.5rem;
  width: 50%;
  height: 47rem;
  overflow: hidden;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.5);
  position: relative;
}

.nft-image-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nft-caption {
  width: 100%;
  padding: 0.3rem;
  padding-inline: 1.2rem;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-crypto-icon-content {
  font-size: 2.3rem;
}

.nft-image-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.nft-image-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.nft-image-button-icon {
  font-size: 1.6rem;
}

.nft-info {
  border: 1px solid var(--primery-color);
  width: 50%;
  height: 47rem;
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
}

.nft-name-creator-and-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.nft-name {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 1rem;
}

.nft-creator {
  font-size: 1.2rem;
  color: var(--text-color);
}

.nft-creator-span {
  color: var(--primery-color);
}

.nft-button-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nft-btn {
  padding: 0.3rem;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  outline: none;
  transition: all ease 0.3s;
}

.nft-btn:hover {
  background-color: var(--primery-color);
}

.nft-btn-icon {
  font-size: 1.2rem;
}

.nft-info-box {
  border: 1px solid var(--primery-color);
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.nft-info-box-title {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.4px;
  border-bottom: 1px solid var(--primery-color);
}

.nft-info-box-text {
  color: var(--text-color);
  font-size: 1rem;
  padding: 1rem;
}

.nft-description-content {
  padding: 3rem 1rem;
}

.nft-deatails-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-deatails-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.nft-deatail-info {
  width: 6rem;
}

.nft-deatail-info-link {
  color: var(--text-color);
  text-decoration: underline;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all ease 0.3s;
}

.nft-deatail-info-link:hover {
  color: var(--primery-color);
}

.nft-offer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  background-color: var(--text-color);
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: 400;
  overflow: hidden;
}
.nft-offer,
.nft-offer-price {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.5rem;
  padding-block: 1rem;
  justify-content: center;
}

.nft-offer-price {
  background: var(--primery-color);
  min-height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.nft-offer-icon {
  font-size: 1.3rem;
}

/*============nft-more-section=============*/

.nft-more-section {
  margin-top: 3rem;
}

.nft-info-box-more-title {
  font-size: 1.3rem;
}

.nft-info-box-content {
  color: var(--text-color);
  padding: 1rem;
  font-size: 1rem;
}

.nft-more-box {
  width: 90%;
  margin: 0 auto;
}

.slider {
  display: flex;
  align-items: center;
  padding: 2rem;
  gap: 1rem;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.slider::-webkit-scrollbar {
  width: 100%;
  background-color: var(--scroll-bar-background);
  border-radius: 2rem;
  box-shadow: 0 10px 1rem rgba(0, 0, 0, 0.3);
}

.slider::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background-color: var(--scroll-bar-thumb-color);
}

.slider::-webkit-scrollbar-thumb {
  border-radius: 3rem;
}

.nft-box-link {
  display: inline-block;
}

.nft-box {
  width: 20rem;
  height: 30rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: all ease 0.3s;
}

.nft-box-link:hover .nft-box {
  transform: translateY(-10px);
}

.nft-box-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nft-box-description {
  position: absolute;
  width: 100%;
  padding: 1rem;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--text-second-color);
}

.nft-name-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.nft-box-name,
.nft-box-price {
  font-size: 0.9rem;
  font-weight: 600;
}

.nft-box-crator-name {
  font-size: 0.9rem;
  font-weight: 400;
}

.view-collection-btn-wrapper {
  margin: 0 auto;
  margin-top: 3rem;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.view-collection-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primery-color);
  cursor: pointer;
  transition: all ease 0.2s;
  font-size: 1.3rem;
}

.view-collection-btn:hover {
  color: var(--text-hover-color);
}

/*==========footer-==============*/

.footer {
  margin-top: 1rem;
  position: relative;
  z-index: 2000;
  margin-top: 2rem;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .nft-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 956px) {
  .nft-info {
    height: 49rem;
  }
  .nft-image {
    height: 49rem;
  }

  .slider::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 857px) {
  .main {
    padding: 1rem;
  }

  .nft-content-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .nft-more-box {
    width: 100%;
  }

  .nft-info {
    width: 100%;
    height: auto;
  }
  .nft-image {
    width: 100%;
    height: 32rem;
  }

  .nft-info-box-content {
    padding: 1;
  }

  .nft-box {
    width: 17rem;
  }

  .slider {
    padding: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .nft-info-box {
    margin-bottom: 0.5rem;
  }
}
