* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: var(--background-color);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}
/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

.create-nft-container {
  position: relative;
}

.top-background-light {
  width: 200px;
  height: 200px;
  filter: blur(100px);
  position: absolute;
  top: -100px;
  left: -100px;
}

/*==============create-nft-section==========*/

.create-nft-section {
  padding-block: 1rem;
}

.create-nft-main-title-wrapper {
  padding-inline: 2rem;
  margin-bottom: 1.5rem;
}

.create-nft-heading-primary {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 600;
}

.header-text {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--text-color);
}

.create-nft-content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10%;
  padding: 2rem;
  height: 59rem;
}

/*---------upload-file----------*/

.upload-nft-box {
  transform: translateY(40px);
  border: 1px dashed var(--primery-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  border-radius: 1rem;
}

.upload-nft-box-content-wrapper {
  text-align: center;
}

.upload-nft-icon {
  font-size: 2rem;
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.upload-nft-button {
  display: block;
  text-decoration: underline;
  color: var(--text-color);
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all ease 0.3s;
}

.upload-nft-button:hover {
  color: var(--primery-color);
}

.upload-text {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 400;
}

.upload-size-text {
  margin-bottom: 0.5rem;
}

/*------------uplod-form----------------*/

.upload-form {
  width: 50%;
}

.upload-form-group-title {
  font-size: 1.3rem;
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.create-collection-button {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all ease 0.3s;
  background: linear-gradient(
    to bottom,
    var(--text-color),
    var(--text-fourth-color)
  );
  color: var(--text-second-color);
  box-shadow: 0 0.5rem 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 1.5rem;
}

.create-collection-button:hover {
  background: var(--primery-color);
  transform: translateY(-5px);
  box-shadow: 0 0.2rem 5px rgba(0, 0, 0, 0.3);
}

.create-collection-button:active {
  transform: translateY(0);
}

.create-collection-shap {
  width: 50px;
  height: 50px;
  background-color: var(--background-color);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-collection-shap-icon {
  color: var(--text-color);
  font-size: 1rem;
}

.create-collection-text {
  font-size: 1.05rem;
  font-weight: 400;
}

.upload-form-group {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.upload-form-input {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: none;
  background: transparent;
  color: var(--text-color);
  letter-spacing: 0.5px;
}

.upload-form-input[type='number']::-webkit-inner-spin-button,
.upload-form-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.description-box-wrapper {
  height: 8rem;
}

.description-box {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: var(--text-color);
  resize: none;
}

.category-wrapper {
  border: none;
}

.upload-form-text {
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 1rem;
}

/*------drop-btn-------*/

.drop-button-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-btn {
  min-width: 180px;
  height: 40px;
  position: relative;
}

.select {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

/*clicked
(add later in javascript)
*/

.select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.select:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

.cart {
  font-size: 1.1rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.cart-rotate {
  transform: rotate(180deg);
}

.drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  position: absolute;
  top: 3em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 5;
}

.drop-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}

.drop-menu li:hover {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-open {
  display: block;
}

/*--------drop-buttons-end----------*/

.create-btn-wrapper {
  margin-top: 2rem;
  text-align: end;
}

.create-btn {
  border: none;
  outline: none;
  background-color: var(--primery-color);
  color: var(--text-second-color);
  font-size: 1.2rem;
  font-weight: 500;
  height: 45px;
  width: 180px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0.5rem 10px rgba(0, 0, 0, 0.3);
  transition: all ease 0.3s;
}

.create-btn:hover {
  background-color: transparent;
  border: 1px solid var(--primery-color);
  color: var(--text-color);
}

/*============making-responsive===============*/

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1000px) {
  .create-nft-content-wrapper {
    gap: 1rem;
  }
}

@media screen and (max-width: 820px) {
  .create-nft-content-wrapper {
    flex-direction: column;
  }

  .upload-nft-box {
    width: 100%;
    min-height: 20rem;
  }

  .upload-form {
    width: 100%;
    height: auto;
    padding-block: 2rem;
  }

  .upload-nft-box {
    transform: translateY(0);
  }
}

@media screen and (max-width: 435px) {
  .drop-button-wrapper {
    flex-direction: column;
    gap: 2rem;
  }
}

.icon-upload-create-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
