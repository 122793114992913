/*--------background------*/

.background-lights {
    z-index: -1;
    border-radius: 50%;
    background-color: var(--banner-img-circles-color);
}

/*=======header-top-section=================*/

/*--background-light---*/

.background-light {
    background-color: var(--banner-img-circles-color);
    position: absolute;
}

/*--header-background---*/

.header-background-light {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: -1;
    filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
    background-color: var(--background-color);
    padding: 10px 0;
    box-shadow: 0 0 10px #000;
    position: sticky;
    top: 0;
    z-index: 10000;
}


.navigation {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
    justify-content: space-between;

}

.nav-left-wrapper a {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
}



.nav-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 1rem;
}


.nav-list-wrapper {
    width: 40%;
}

.nav-list-wrapper .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}





.nav-right-wrapper .menu li a {
    color: var(--text-color);
    transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
    color: var(--primery-color);
}


.nav-search-and-link-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}


.nav-search-and-link-wrapper form {
    width: 95%;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--primery-color);
    border-radius: 5px;
    position: relative;
}


.nav-search-and-link-wrapper .search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    background-color: transparent;
    margin-left: 1rem;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
    position: absolute;
    width: 30px;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
    font-size: 1.1rem;
}



.nav-link-wrapper {
    display: flex;
    align-items: center;
    width: 15%;
    gap: 1rem;
    justify-content: space-between;
}

.shop-btn {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primery-color);
    border-radius: 5px;
    color: var(--text-second-color);
    font-size: 1.2rem;
    transition: all ease 0.3s;
}

.nav-link-btns:hover {
    background-color: rgb(91, 91, 5);
}



/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
    position: relative;
}

.select-profile {
    height: 36px;
    width: 36px;
    background: var(--primery-color);
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 1.3rem;
    font-weight: 300;
}


.dropdown-profile-mobile-size-item {
    display: none;
}



.select-profile:hover {
    background: rgb(91, 91, 5);
}



/* 
Rotate styles
(add later in javascript)
*/



.drop-menu-profile {
    list-style: none;
    padding: 0 0.1rem;
    background: #ffffffdd;
    border: 1px solid #353a43;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: #000;
    position: absolute;
    top: 3em;
    top: 100%;
    left: -188%;
    width: 200px;
    text-align: center;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
    background-color: var(--background-color);
}


.drop-menu-profile li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1rem;
    background-color: var(--text-color);

}

.drop-menu-profile li a {
    font-size: 1.1rem;
    color: var(--text-second-color);
    font-weight: 500;
}

.drop-menu-profile li:hover {
    background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
    background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/


.drop-menu-profile-open {
    display: block;
}

/*profile-drop-down-btn-end*/


/*---navigation-end----*/

/*===============header=======================*/

.header{
    height: 70vh;
    background-image: linear-gradient(to right ,rgba(0,0,0,.7),rgba(255,255,255,.3)),url(../assets//images//about-us-header-img.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 73% 99%, 0% 72%);
    position: relative;
}

.header::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 72%;
    bottom: 0;
    background: rgb(241, 196, 15,.5);
    clip-path: polygon(23% 51%, 76% 69%, 100% 53%, 100% 100%, 25% 100%, 0 100%, 0 59%);
    -webkit-clip-path:polygon(23% 51%, 76% 69%, 100% 53%, 100% 100%, 25% 100%, 0 100%, 0 59%);
}

.about-heading-primary{
    color: var(--text-color);
    position:absolute;
    top: 20%;
    left: 10%;        
    line-height: 2.5rem;
}

.heading-primary-main{
    display: block;
    color: var(--primery-color);
    font-weight: 500;
    font-size: 2.5rem;
    animation: textLeftMove ease-in-out 3s;
}

.heading-primary-sub{
    display: block;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--text-color);
    animation:textRightMove ease-in-out 3s;
}

@keyframes textLeftMove{
    0%{
        transform: translateX(-100px);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
} 

 @keyframes textRightMove{
    0%{
        transform: translateX(100px);
        opacity: 0;
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

/*=================main=================*/
.main{
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.welcome-img-box-wrapper{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.left-img-wrapper{
    transform: translateY(-2rem);
}

.welcome-img-box{
    width:180px ;
    height:180px ;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
    transition: all ease 0.3s;
}

.welcome-img-box:hover{
    transform: translateY(-10px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.welcome-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.welcome-content-wrapper{
    display: flex;
    gap: 2rem;
    padding-inline: 2rem;
}

.welcome-text-wrapper{
    padding-block: 1rem;
}

.heading-secondary{
    color: var(--primery-color);
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.paragraph{
    font-size: 1.5rem;
    color: var(--text-color);
    line-height: 3rem;
}

/*==============our-mission-section============*/

.our-mission-section{
    margin-top: 15rem;
}

.our-mission-box{
    margin: 0 auto;
    width: 90%;
    border-bottom: 2px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 4rem;
    position: relative;

}


.our-mission-title-box{
    background-color: var(--primery-color);
    width: 40%;
    padding: 0.5rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.our-mission-title{
    font-size:1.5rem ;
    font-weight: 500;
}

/*==============why-us-section============*/

.why-us-section{
    margin-top: 15rem;
}

.why-us-title-box{
    border: 2px solid var(--primery-color);
    width: 40%;
    height: 90px;
    margin: 0 auto;
    border-bottom: none;
    position: relative;
}


.why-us-title{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text-color);
    font-size: 1.8rem;
    font-weight: 600;
    width: 40%;
    text-align: center;
    background-color: var(--background-color);
}

.arrows::after{
    position: absolute;
    content: '';
    border: 2px solid var(--primery-color);
    width: 10px;
    height: 10px;
    bottom: 0;
    left: -7px;
    display: block;
    transform: rotate(45deg);
    border-top: none;
    border-left: none;
}

.arrows::before{
    position: absolute;
    content: '';
    border: 2px solid var(--primery-color);
    width: 10px;
    height: 10px;
    bottom: 0;
    right: -7px;
    display: block;
    transform: rotate(45deg);
    border-top: none;
    border-left: none;
}

.why-us-boxes-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
    margin-top: 2rem;
}

.why-us-box{
    padding: 1rem 2rem ;
    width: 31%;
    min-height:14rem ;
    background-color: var(--primery-color);
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    transition: all ease 0.5s;
}

.why-us-box:hover:first-child{
    background-color: var(--primery-color);
    box-shadow:0 5px 10px var(--primery-color);
    transform:translate(-10px,-10px) ;
}

.why-us-box:hover:last-child{
    background-color: var(--primery-color);
    box-shadow:0 5px 10px var(--primery-color);
    transform:translate(10px,-10px) ;
}

.why-us-box-title-wrapper{
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;
}

.why-us-logo{
    width: 40px;
}

.why-us-box-title{
    font-size: 1.5rem;
    color: var(--text-second-color);
}

.why-us-text{
    font-size: 1.1rem;
    font-weight: 400;
}


/*=================join-us-section=========*/

.join-us-section{
    margin-top: 15rem;
    margin-bottom: 15rem;
    height: 85vh;
    background-image: linear-gradient(to right ,rgba(0,0,0,.7),rgba(241, 196, 15,.3)),url(../assets//images/about-us-img-4.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    /* clip-path: polygon(30% 0, 100% 11%, 100% 100%, 62% 93%, 0 100%, 0% 60%, 0 9%); */
    clip-path: polygon(100% 9%, 100% 100%, 67% 98%, 38% 100%, 0 100%, 0 15%, 18% 0);
}

.join-us-section::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 72%;
    top: -15%;
    background: rgb(241, 196, 15,.5);
    clip-path: polygon(100% 0, 100% 36%, 66% 46%, 13% 40%, 0 54%, 0 0, 17% 0);
}

.join-us-section::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 72%;
    bottom: 0%;
    background: rgb(241, 196, 15,.5);
    clip-path: polygon(100% 94%, 100% 100%, 68% 100%, 18% 100%, 0 100%, 0 100%, 66% 91%);
}


.join-us-text-wrapper{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-100%,-50%);
    width: 40%;
}

/*===============footer=============*/

.footer {
    margin-top: 5rem;
    position: relative;
    z-index: 2000;

}

.footer-wrapper {
    border-top: 20px solid var(--primery-color);
    width: 100%;
    background-color: #212121;
    padding: 1rem;
    box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


.footer-left-wrapper {
    padding: 3rem;
    width: 30%;
}

.social-links-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 3rem;
    flex-wrap: wrap;
}

.social-links-wrapper a {
    color: #b0b0b0;
}


.social-links-wrapper a:hover {
    color: var(--primery-color);
}

.footer-left-wrapper strong {
    display: inline-block;
    font-size: 2rem;
    color: #7E4087;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}

.footer-left-wrapper p {
    max-width: 350px;
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;

}

.footer-right-wrapper {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.footer-right-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
    color: var(--primery-color);
}

.footer-right-wrapper ul a {
    color: var(--text-color);
    font-weight: 300;
    font-size: 1.2rem;
    transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
    color: var(--primery-color);
}



/*------making-responsive-------------*/

@media screen and (max-width:1200px) {

    .container {
        max-width: 1200px;
    }

    .navigation {
        width: 95%;
    }

    .nav-list-wrapper .menu {
        gap: 2rem;
    }



    .dropdown-btn {
        min-width: 140px;
        min-height: 30px;
    }


    .footer-right-wrapper,
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-left-wrapper {
        min-width: 300px;
    }

    .footer-right-wrapper ul {
        min-width: 160px;
        margin: 1rem;
    }


}



@media screen and (max-width:1100px) {



    .nav-list-wrapper {
        display: none;
    }

    .dropdown-profile-mobile-size-item {
        display: block;
    }

    .nav-right-wrapper {
        justify-content: end;
    }


    .nav-search-and-link-wrapper {
        width: 100%;
    }

    .nav-search-and-link-wrapper form {
        width: 70%;
        margin-left: 1rem;
    }

    .nav-link-wrapper {
        gap: 1rem;
        justify-content: center;
    }


}


@media screen and (max-width:1000px){
    .welcome-content-wrapper{
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        gap: 5rem;
    }

    .our-mission-box{
        width: 90%;
    }

    .why-us-title-box{
        width: 70%;
    }

    .why-us-boxes-wrapper{
        width: 90%;
        margin: 2rem auto 2rem auto;
        justify-content: space-between;
    }

    .why-us-box{
        width: 50%;
        padding: 1rem;
        min-height: 15rem;
    }
    .paragraph{
        text-align: center;
        font-size: 1.1rem;
    }

    .why-us-box-title{
        font-size: 1.2rem;
    }
    .why-us-text{
        font-size: .9rem;
    }

    .join-us-text-wrapper{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
    }
}






@media screen and (max-width:650px) {

    .nav-search-and-link-wrapper form {
        display: none;
    }

    .footer-content-wrapper {
        flex-direction: column;
    }

    .select {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .dropdown-btn {
        width: 130px;
        height: 30px;
    }


    .drop-menu {
        background-color: var(--text-second-color);
        color: var(--text-color);
        position: relative;
        padding: 0.1rem;
    }


    .drop-menu li {
        gap: 1px;
    }


}


@media screen and (max-width:550px) {

    .nav-left-wrapper a {
        font-size: 1.5rem;
    }

    .our-mission-title-box{
        width: 80%;
    }

    .search-and-sort-wrapper {
        flex-direction: column;
    }

    .our-mission-box{
        padding: 3rem 1rem 3rem 1rem;
    }

    .why-us-boxes-wrapper{
        flex-direction: column;
        gap: 3rem;
    }

    .why-us-box{
        width: 100%;
        height: 10rem;
    }

    .why-us-title-box{
        border: none;
    }

    
    .arrows::after,
    .arrows::before{
        border: none;
    }

}



@media screen and (max-width:457px){


    .why-us-title{
        width: 80%;
    }

    .welcome-img-box-wrapper{
        flex-direction: column;
        gap: 1rem;
    }

    .left-img-wrapper{
        transform: translateY(0rem);
    }

    .welcome-img-box{

        width: 90%;
        height: 180px;
        margin: 0 auto 2rem auto;
    }

    .welcome-img{
        object-fit: cover;
    }

    .heading-primary{
        text-align: center;
    }
} 