

.podcast-container {
    max-width: 1300px;
    margin: 0 auto;
}

/*--------background------*/

.background-lights {
    z-index: -1;
    border-radius: 50%;
    background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

.podcast-header {

    position: relative;
}

/*--background-light---*/

.background-light {
    background-color: var(--banner-img-circles-color);
    position: absolute;
}

/*--header-background---*/

.header-background-light {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: -1;
    filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
    background-color: var(--background-color);
    padding: 10px 0;
    box-shadow: 0 0 10px #000;
    position: sticky;
    top: 0;
    z-index: 10000;
}


.navigation {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
    justify-content: space-between;

}

.nav-left-wrapper a {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
}



.nav-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 1rem;
}


.nav-list-wrapper {
    width: 40%;
}

.nav-list-wrapper .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}





.nav-right-wrapper .menu li a {
    color: var(--text-color);
    transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
    color: var(--primery-color);
}


.nav-search-and-link-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}


.nav-search-and-link-wrapper form {
    width: 95%;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--primery-color);
    border-radius: 5px;
    position: relative;
}


.nav-search-and-link-wrapper .search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    background-color: transparent;
    margin-left: 1rem;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
    position: absolute;
    width: 30px;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
    font-size: 1.1rem;
}



.nav-link-wrapper {
    display: flex;
    align-items: center;
    width: 15%;
    gap: 1rem;
    justify-content: space-between;
}

.shop-btn {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primery-color);
    border-radius: 5px;
    color: var(--text-second-color);
    font-size: 1.2rem;
    transition: all ease 0.3s;
}

.nav-link-btns:hover {
    background-color: rgb(91, 91, 5);
}



/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
    position: relative;
}

.select-profile {
    height: 36px;
    width: 36px;
    background: var(--primery-color);
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 1.3rem;
    font-weight: 300;
}


.dropdown-profile-mobile-size-item {
    display: none;
}



.select-profile:hover {
    background: rgb(91, 91, 5);
}



/* 
Rotate styles
(add later in javascript)
*/



.drop-menu-profile {
    list-style: none;
    padding: 0 0.1rem;
    background: #ffffffdd;
    border: 1px solid #353a43;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: #000;
    position: absolute;
    top: 3em;
    top: 100%;
    left: -188%;
    width: 200px;
    text-align: center;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
    background-color: var(--background-color);
}


.drop-menu-profile li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1rem;
    background-color: var(--text-color);

}

.drop-menu-profile li a {
    font-size: 1.1rem;
    color: var(--text-second-color);
    font-weight: 500;
}

.drop-menu-profile li:hover {
    background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
    background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/


.drop-menu-profile-open {
    display: block;
}

/*profile-drop-down-btn-end*/


/*---navigation-end----*/

/*=====header============================*/

.podcast-header{
    margin: 0 auto;
    width: 95%;
}

.podcast-header-content-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
}

/*-------text-part-of-header------*/

.podcast-header-text-box {
    margin-top: 3rem;
    width: 50%;
}

.heading-text {
    margin-bottom: 2rem;
}

.heading-text-main {
    font-size: 2rem;
    color: var(--text-color);
    display: block;
    letter-spacing: .5px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.heading-text-sub {
    font-size: 1.3rem;
    display: block;
    color: var(--text-color);
    font-weight: 500;
    line-height: 2.9rem;
    margin-bottom: .5rem;
}

.heading-caption {
    font-size: 1.1rem;
    display: block;
    color: var(--text-fourth-color);
    font-weight: 300;
    line-height: 2.9rem;
}

.header-btn {
    display: inline-block;
    background-color: var(--primery-color);
    padding: .5rem 1rem;
    border-radius: 10px;
    color: var(--text-second-color);
    font-weight: 500;
    box-shadow: inset rgba(0, 0, 0, 1) 0 0 5px, 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
}

.header-btn:hover {
    background-color: transparent;
    border: 1px solid var(--primery-color);
    color: var(--text-color);
    box-shadow: none;
}

/*-------hero-part-of-header------*/

.hero-main-wrapper {
    margin-top: -2rem;
    width: 50%;
}

.hero-img{
    width: 100%;
}


/*==================main==============*/

.podcast-main {
    margin-top: 10rem;
}

/*================Latest-Episode-section===========*/
.lastest-episodes-section{
    width: 90%;
    margin:  0 auto;
    margin-bottom: 0;
}

.title-and-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.podcast-heading-secondary {
    color: var(--text-color);
    letter-spacing: .5px;
    font-size: 1.8rem;
    font-weight: 400;
}

.view-all-btn {
    color: var(--primery-color);
    font-size: 1.4rem;
    cursor: pointer;
    transition: all ease 0.3s;
}

.view-all-btn:hover {
    color: var(--text-hover-color);
}

.podcast-boxes-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.podcast-boxes-wrapper::-webkit-scrollbar {
    display: none;
}

.podcast-box{
    position: relative;
    border-radius: 10px;
    min-width:300px ;
    height: 20rem;
    overflow: hidden;
    box-shadow: 0 .8rem 1rem rgba(0, 0, 0, 0.2);
    transition: all ease .5s;
}

.podcast-box-overlay{
    display: block;
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 100%;
    transition: all ease .5s;
    background: rgba(0, 0, 0, .7);
    bottom: 0;
    left: 0;
    transition: all ease .5s;
    border-radius: 10px;
}

.date-and-episode-box {
    position: absolute;
    right: 2%;
    top: 2%;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: .2rem .8rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1.5rem;
    backdrop-filter: blur(10px);
}

.date,
.episode {
    color: var(--text-fourth-color);
    font-size: .8rem;
}

.podcast-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.podcast-text-and-play-button-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.podcast-box-label {
    background-color: var(--primery-color);
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .8rem 1rem rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
    color: var(--text-color);
    cursor: pointer;

}

.podcast-box-label:hover {
    background-color: var(--text-hover-color);
}

.podcast-box-text {
    font-size: .8rem;
    color: var(--text-color);
}

.podcast-pause-icon {
    display: none !important;
}

#play-1:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-2:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-3:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-4:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-5:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-6:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-7:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon,
#play-8:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-play-icon {
    display: none;
}

#play-1:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-2:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-3:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-4:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-5:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-6:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon,
#play-7:checked~.podcast-text-and-play-button-wrapper .podcast-box-label .podcast-pause-icon{
    
    display: block !important;
}

#play-1:checked~.podcast-box-overlay,
#play-2:checked~.podcast-box-overlay,
#play-3:checked~.podcast-box-overlay,
#play-4:checked~.podcast-box-overlay,
#play-5:checked~.podcast-box-overlay,
#play-6:checked~.podcast-box-overlay,
#play-7:checked~.podcast-box-overlay {
    height: 30%;
}

#play-1:checked~.music-loader-box,
#play-2:checked~.music-loader-box,
#play-3:checked~.music-loader-box,
#play-4:checked~.music-loader-box,
#play-5:checked~.music-loader-box,
#play-6:checked~.music-loader-box,
#play-7:checked~.music-loader-box {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
}

.music-loader-box {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    height: 70px;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all ease .3s;
    opacity: 0;
    visibility: none;
}

.stroke {
    background-color: var(--background-color);
    display: block;
    width: 7px;
    height: 100%;
    border-radius: 50px;
    animation: animate .8s linear infinite;
}

@keyframes animate {
    50% {
        height: 20%;
    }

    100% {
        height: 100%;
    }
}

.stroke:nth-child(1) {
    animation-delay: 0s;
}

.stroke:nth-child(2) {
    animation-delay: 0.3s;
}

.stroke:nth-child(3) {
    animation-delay: 0.6s;
}

.stroke:nth-child(4) {
    animation-delay: 1s;
}

.stroke:nth-child(5) {
    animation-delay: 1.2s;
}

.stroke:nth-child(6) {
    animation-delay: 1.5s;
}

.stroke:nth-child(7) {
    animation-delay: 1.8s;
}

.stroke:nth-child(8) {
    animation-delay: 2.1s;
}

.stroke:nth-child(9) {
    animation-delay: 2.4s;
}

.stroke:nth-child(10) {
    animation-delay: 2.7s;
}

.stroke:nth-child(11) {
    animation-delay: 3s;
}

.stroke:nth-child(12) {
    animation-delay: 3.3s;
}

.stroke:nth-child(13) {
    animation-delay: 3.6s;
}

.stroke:nth-child(14) {
    animation-delay: 3.9s;
}

.stroke:nth-child(15) {
    animation-delay: 4.2s;
}

/*===================article-banner-section===================*/

.article-banner-section{
    margin-top: 10rem;
   padding-block: 2rem;
}

.article-content-wrapper{
    display: flex;
    gap: 10%;
    width: 100%;
    align-items: center;
}

.article-banner-box{
    width: 40%;
    border-radius: 2px 50px 2px 2px ;
    overflow: hidden;
    height: 30rem;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.article-banner{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.article-heading-primary{
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 700;
}

.article-heading-secondary{
    font-size: 2rem;
    color: var(--text-color);
    letter-spacing: .5px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.article-text-box{
    width: 60%;
    position: relative;
}

.article-heading-sub{
    display: block;
    font-size: 1.8rem;
    font-weight: 300;
}

.article-pragraph{
    font-size: 1.3rem;
    font-family: 400;
    color: var(--text-color);
    width: 65%;
    line-height: 3rem;
    margin-bottom: 2rem;
}

.article-btn {
    display: inline-block;
    background-color: var(--primery-color);
    padding: .5rem 1rem;
    border-radius: 10px;
    color: var(--text-second-color);
    font-weight: 500;
    box-shadow: inset rgba(0, 0, 0, 1) 0 0 5px, 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
}

.article-btn:hover {
    background-color: transparent;
    border: 1px solid var(--primery-color);
    color: var(--text-color);
    box-shadow: none;
}

.article-banner-background-light{
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    filter: blur(90px);
}

/*==============article-boxes-wrapper===============*/

.latest-articles-section{
    width: 90%;
    margin: 0 auto;
    margin-top: 10rem;
}


.lastest-article-episodes-section{
    width: 90%;
    margin:  0 auto;
    margin-bottom: 3rem;
}

.latest-article-episodes-content-wrapper {
    margin-bottom: 5rem;
}

.title-and-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.article-heading-secondary {
    color: var(--text-color);
    letter-spacing: .5px;
    font-size: 1.8rem;
    font-weight: 400;
}

.view-all-btn {
    color: var(--primery-color);
    font-size: 1.4rem;
    cursor: pointer;
    transition: all ease 0.3s;
}

.view-all-btn:hover {
    color: var(--text-hover-color);
}

.article-boxes-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: auto;
    scroll-behavior: smooth;
}

.article-boxes-wrapper::-webkit-scrollbar {
    display: none;
}

.article-box{
    position: relative;
    border-radius: 10px;
    min-width:300px ;
    height: 20rem;
    overflow: hidden;
    box-shadow: 0 .8rem 1rem rgba(0, 0, 0, 0.2);
    transition: all ease .5s;
}


.article-view-link-icon{
    color: var(--text-color);
}

.article-view-link{
    background-color: var(--primery-color);
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .8rem 1rem rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
    color: var(--text-color);
    cursor: pointer;
}


.article-view-link:hover {
    background-color: var(--text-hover-color);
}



.article-box-text{
    font-size: .8rem;
    color: var(--text-color);
    position: relative;
}

.article-box-overlay{
    display: block;
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 100%;
    transition: all ease .5s;
    background: rgba(0, 0, 0, .7);
    bottom: 0;
    left: 0;
    transition: all ease 1s;
    border-radius: 10px;
}

.date-and-episode-box {
    position: absolute;
    right: 2%;
    top: 2%;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: .2rem .8rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 1.5rem;
    backdrop-filter: blur(10px);
}

.date,
.episode {
    color: var(--text-fourth-color);
    font-size: .8rem;
}

.article-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
}

.article-text-and-play-button-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}


.article-box:hover .article-box-overlay{
    height: 30%;
}

.next-perv-btn-container {
    position: relative;
    max-width: 90%;
    margin: 2rem auto 20rem auto;
}


.slider-btn:hover {
    transform: scale(1.2);
}




.slider-btn:hover .slider-btn-border {
    animation: border-rotate ease 0.2s;
}

@keyframes border-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.slider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    width: 70px;
    height: 70px;
    z-index: 1;
    top: 40%;
    cursor: pointer;
    transition: all ease 0.5s;
}

.slider-btn i {
    color: var(--primery-color);
    font-size: 1.5rem;
}

.slider-btn-border {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    border: 2px solid var(--primery-color);
    top: 0;
    transition: all ease 0.2s;
    transform: rotate(40deg);

}

.perv-btn-border {
    border-right: none;
    border-top: none;
}

.next-btn-border {
    border-left: none;
    border-bottom: none;
}
#section-next-btn{
    right: 0;
}

#section-perv-btn{
    left: 0;
}

/*===============footer=============*/

.footer {
    margin-top: 5rem;
    position: relative;
    z-index: 2000;

}

.footer-wrapper {
    border-top: 20px solid var(--primery-color);
    width: 100%;
    background-color: #212121;
    padding: 1rem;
    box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


.footer-left-wrapper {
    padding: 3rem;
    width: 30%;
}

.social-links-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 3rem;
    flex-wrap: wrap;
}

.social-links-wrapper a {
    color: #b0b0b0;
}


.social-links-wrapper a:hover {
    color: var(--primery-color);
}

.footer-left-wrapper strong {
    display: inline-block;
    font-size: 2rem;
    color: #7E4087;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}

.footer-left-wrapper p {
    max-width: 350px;
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;

}

.footer-right-wrapper {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.footer-right-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
    color: var(--primery-color);
}

.footer-right-wrapper ul a {
    color: var(--text-color);
    font-weight: 300;
    font-size: 1.2rem;
    transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
    color: var(--primery-color);
}


/*------making-responsive-------------*/

@media screen and (max-width:1200px) {

    .podcast-container {
        max-width: 1200px;
    }

    .navigation {
        width: 95%;
    }

    .nav-list-wrapper .menu {
        gap: 2rem;
    }

    .dropdown-btn {
        min-width: 140px;
        min-height: 30px;
    }


    .footer-right-wrapper,
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-left-wrapper {
        min-width: 300px;
    }

    .footer-right-wrapper ul {
        min-width: 160px;
        margin: 1rem;
    }


}


@media screen and (max-width:1150px){
    
    .podcast-header-content-wrapper{
        gap: 5%;
        flex-direction: column-reverse;
        align-items: center;
    }

    .podcast-header-text-box{
        text-align: center;
        width: 80%;
    }

    .hero-img{
        width: 500px;
    }

}


@media screen and (max-width:1100px) {



    .nav-list-wrapper {
        display: none;
    }

    .dropdown-profile-mobile-size-item {
        display: block;
    }

    .nav-right-wrapper {
        justify-content: end;
    }


    .nav-search-and-link-wrapper {
        width: 100%;
    }

    .nav-search-and-link-wrapper form {
        width: 70%;
        margin-left: 1rem;
    }

    .nav-link-wrapper {
        gap: 1rem;
        justify-content: center;
    }


}


@media screen and (max-width:950px){
    .next-perv-btn-container{
        display: none;
    }

    .article-pragraph{
        width: 100%;
    }

    .article-content-wrapper{ 
        flex-direction: column;
    }

    .article-banner-box{
        width: 90%;
        border-radius: 2rem;
        height: 25rem;
        margin-bottom: 3rem;
    }

    .article-text-box{
        width: 90%;
        text-align: center;
    }
} 



@media screen and (max-width:650px) {

    .nav-search-and-link-wrapper form {
        display: none;
    }

    .footer-content-wrapper {
        flex-direction: column;
    }

    .select {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .dropdown-btn {
        width: 130px;
        height: 30px;
    }


    .drop-menu {
        background-color: var(--text-second-color);
        color: var(--text-color);
        position: relative;
        padding: 0.1rem;
    }


    .drop-menu li {
        gap: 1px;
    }


}


@media screen and (max-width:550px) {

    .nav-left-wrapper a {
        font-size: 1.5rem;
    }

    .search-and-sort-wrapper {
        flex-direction: column;
    }

    .hero-img{
        width: 90%;
    }

}