/*===============colors================*/

:root {
  --background-color: #333333;
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-img-light-color: radial-gradient(
    50% 50% at 50% 50%,
    rgba(234, 57, 210, 0) 0%,
    rgba(241, 196, 15, 0.8) 0%,
    rgba(234, 57, 210, 0.168) 0.01%,
    rgba(48, 192, 209, 0) 100%
  );
  --text-color: #fff;
  --text-second-color: #000;
  --banner-img-circles-color: #7e4087;
  --text-hover-color: #b19310;
  --history-item-background: rgb(200, 200, 200);
  --history-item-background-gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --history-date-box-background: rgb(241, 196, 15);
  --history-date-box-background-gradient: linear-gradient(
    -90deg,
    rgba(241, 196, 15, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
}

/*=============importing fonts======================*/

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

.z-index {
  z-index: 100;
}

.profile-container {
  max-width: 1300px;
  margin: 0 auto;
}

.profile-header-banner-section {
  background: url(../assets//images/profile-banner-img.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/*--------background------*/

.profile-upload-btn {
  height: 50%;
}

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

.profile-top-background-light {
  position: absolute;
  width: 100px;
  height: 100px;
  filter: blur(60px);
  top: 0;
}

/*--for-making-it-stuck--*/

.profile-content-wrapper {
  position: relative;
  width: 99%;
  margin: 0 auto;
}

/*=======header-section=================*/

header {
  position: relative;
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 99%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*==============profile-top-section=============*/

.profile-img-wrapper {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  transform: translateY(-112px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
}

.profile-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Edit mode Overlay */

.profile-img-wrapper.editing .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* پس‌زمینه خاکستری شفاف */
}

.overlay {
  display: none;
}

.edit-button {
  position: absolute;
  background-color: #ffffffb3;
  border: none;
  padding: 2px 2px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  bottom: 0;
  width: 100%;
}

.edit-button:hover {
  background-color: #ffffff7a;
}

.profile-upload-icon {
  height: 30px;
  width: 70px;
  color: #504f4f;
}

.profile-banner-edit {
  position: absolute;
  left: 50%;
  bottom: 70%;
  padding: 2px 2px;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  background-color: #ffffffb3;
  width: 100px;
  height: 50px;
}

.profile-banner-edit:hover {
  background-color: #ffffff7a;
}

.banner-upload-icon {
  height: 30px;
  width: 70px;
  color: #504f4f;
}

.profile-name-input {
  margin-bottom: 10px;
  font-size: 18px;
  padding: 5px;
  text-align: center;
  border: none;
  border-radius: 4px;
  margin-left: 5px;
}
/* End */

.profile-caption {
  transform: translateY(-80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-name {
  margin-bottom: 10px;
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 600;
}

.profile-join-date {
  font-size: 1rem;
  color: var(--text-color);
}

.profile-join-date-span {
  color: var(--primery-color);
}

.profile-like-share-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.profile-like-share-buttons-wrapper button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
}

/*================profile-main-section==============*/

.profile-main-section {
  width: 100%;
}

/*=========profile-options-and-button-section=============*/

.profile-option-and-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
}

.profile-options-and-button-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.profile-option-content-wrapper {
  position: relative;
}

.profile-option-content-wrapper {
  border-bottom: 1px solid var(--text-hover-color);
  width: 350px;
}

.profile-options-wrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  padding: 1rem 0;
  color: var(--text-color);
  transition: all ease 0.3s;
  position: relative;
  margin-left: 10px;
}

.profile-options-wrapper button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  font-size: 1.1rem;
  z-index: 1;
}

.active-box {
  position: absolute;
  background: var(--primery-color);
  bottom: -1.5px;
  height: 2px;
  transition: all ease 0.5s;
}

.profile-options-wrapper button:nth-child(1) {
  width: 180px;
}

.profile-options-wrapper .active-box-start-top-section,
button:nth-child(1):hover ~ .active-box {
  width: 130px;
  left: 0;
}

.profile-options-wrapper button:nth-child(2) {
  width: 100px;
}

.profile-options-wrapper.active-box-popular,
button:nth-child(2):hover ~ .active-box {
  width: 100px;
  left: 135px;
}

.profile-options-wrapper button:nth-child(3) {
  width: 130px;
}

.profile-options-wrapper .active-box-trending,
button:nth-child(3):hover ~ .active-box {
  width: 100px;
  left: 250px;
}

/*===========profile-drop-down-btns===================*/

.profile-top-dropdown-btn {
  min-width: 180px;
  height: 40px;
  position: relative;
}

.profile-top-dropdown-btn-select {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

/*clicked
(add later in javascript)
*/

.profile-top-dropdown-btn-select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.profile-top-dropdown-btn-select:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

.profile-top-dropdown-btn-cart {
  font-size: 1.1rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.profile-top-dropdown-btn-cart-rotate {
  transform: rotate(180deg);
}

.profile-top-dropdown-btn-drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  position: absolute;
  top: 3em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 5;
}

.profile-top-dropdown-btn-drop-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}

.profile-top-dropdown-btn-drop-menu li:hover {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.profile-top-dropdown-btn-drop-menu-open {
  display: block;
}

/*===========profile-drop-down-btns-end===================*/

/*============main-section-underline============*/

.main-section-underline {
  width: 99%;
  height: 2px;
  background-color: var(--primery-color);
  display: block;
  margin: 2rem auto;
}

/*===========profile-filters-section==============*/

.profile-filters-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-block: 1rem;
  gap: 3rem;
}

.profile-filters-and-boxes-wrapper {
  display: flex;
  margin: 1rem auto;
  gap: 5px;
  padding-inline: 0.3rem;
}

.profile-drop-button-section {
  max-width: 300px;
}

.profile-box-wrapper {
  max-width: 100%;
}

.profile-drop-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/*===========profile-drop-down-btns===================*/

.dropdown-btn {
  width: 220px;
  min-height: 55px;
}

.select {
  background: var(--profile-button-background);
  background: var(--profile-button-background-gradient);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--primery-color);
  border-radius: 2px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 0.9rem;
  font-weight: 300;
  height: 55px;
}

/*clicked
(add later in javascript)
*/

.select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.select:hover,
.select:hover .cart {
  color: var(--primery-color);
}

.cart {
  font-size: 0.8rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.cart-rotate {
  transform: rotate(180deg);
}

.drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: var(--profile-button-background);
  background: var(--profile-button-background-gradient);
  border-bottom: 1px solid var(--primery-color);
  /* box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3); */
  border-radius: 2px;
  color: var(--text-color);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}

.art-style-drop-menu {
  opacity: 1;
  display: block;
}

.color-drop-menu {
  width: 250px;
}

.drop-menu li {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0.3rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.drop-menu li input,
.drop-menu li label {
  cursor: pointer;
}

.drop-menu li input {
  accent-color: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-open {
  display: block;
}

/*===========profile-drop-down-btns-end===================*/

/*=================profile-box-section=================*/

.profile-box-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
}

/*----------profile-item---------*/

.profile-product-box {
  width: 312px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
  box-shadow: 0 0 10px #000000a8;
}

.profile-product-box-img-wrapper {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.profile-product-box-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profile-product-box-caption {
  position: absolute;
  bottom: 0;
  background-color: #ffffffc7;
  width: 100%;
  height: 65px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-second-color);
}

.profile-product-creator {
  font-size: 0.9rem;
  margin-block: 0.3rem;
}

.profile-product-box-caption .caption-deatails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-box-wrapper a {
  transition: all ease 0.4s;
}

.profile-box-wrapper a:hover {
  transform: translateY(-20px);
}

/*===============footer=============*/

.footer {
  margin-top: 10rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}



/* Loading */

.loading-container {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loading-wave {
    width: 300px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.loading-bar {
    width: 20px;
    height: 10px;
    margin: 0 5px;
    background-color: var(--primery-color);
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
    0% {
        height: 10px;
    }

    50% {
        height: 50px;
    }

    100% {
        height: 10px;
    }
}



/*------making-responsive-------------*/

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .profile-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .profile-filters-and-boxes-section {
    padding-inline: 0.6rem;
  }

  .profile-filters-wrapper {
    gap: 0;
  }

  .profile-drop-button-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .profile-option-and-button-wrapper {
    flex-direction: column;
    gap: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }

  .profile-drop-button-wrapper {
    gap: 2rem;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }

  .profile-drop-button-section {
    max-width: 100%;
  }

  .profile-drop-button-wrapper {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .drop-menu {
    background-color: var(--text-second-color);
    color: var(--text-color);
    position: relative;
    padding: 0.1rem;
  }

  .drop-menu li {
    gap: 1px;
  }

  .art-style-drop-menu {
    opacity: 1;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .profile-img-wrapper {
    width: 130px;
    height: 130px;
  }

  .profile-name {
    font-size: 1.2rem;
  }

  .profile-join-date {
    font-size: 0.8rem;
  }

  .profile-content-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .profile-filters-and-boxes-wrapper {
    flex-direction: column;
  }
}
