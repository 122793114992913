:root {
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-color: #333333;
  --text-color: #fff;
  --text-secondry-color: #000;
  --form-background-color: #222;
  --hover-color: rgb(144, 144, 4);
}

/*=============importing fonts======================*/

/* Poppins Black */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: var(--background-color);
  height: 100vh;
}

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
}

.background-light {
  position: absolute;
  border-radius: 50%;
  background-color: blueviolet;
  filter: blur(60px);
  z-index: -1;
}

.light-1 {
  width: 100px;
  height: 100px;
  left: 100px;
  top: 0px;
}

.light-1 {
  width: 100px;
  height: 100px;
  right: 0;
  bottom: 50px;
}

.button-wrapper {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  position: absolute;
  top: 5%;
  right: 0;
  border-radius: 30px;
  width: 250px;
  height: 50px;
  border: 2px solid var(--primery-color);
}

.input_label {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

.login-btn {
  border-right: 2px solid var(--primery-color);
}

.active_box {
  width: 123.4px;
  height: 100%;
  background-color: var(--primery-color);
  border-radius: 30px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  transition: all ease 0.3s;
}

.container #login:checked ~ .button-wrapper .login-btn,
.container #register:checked ~ .button-wrapper .register-btn {
  color: var(--text-secondry-color);
}

.container #login:checked ~ .button-wrapper .active_box {
  left: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.container #register:checked ~ .button-wrapper .active_box {
  left: 123.4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.login-register-wrapper {
  display: flex;
  align-items: center;
  width: 70%;
  height: 650px;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--form-background-color);
  box-shadow: 2px 2px 5px blueviolet;
}

.video-wrapper {
  width: 30%;
  height: 100%;
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*--------------Login--------------*/

.form-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper form {
  padding: 3rem;
  width: 400px;
}

.form-wrapper form h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-color);
  letter-spacing: 1px;
}

.input_wrapper {
  width: 100%;
  height: 30px;
  margin: 1rem 0;
  background-color: transparent;
  position: relative;
  margin-block: 2.5rem;
}

.password-input {
  position: relative;
}

.show-button {
  position: absolute;
  right: 5px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: all ease 0.8ms;
}

.show-button:hover {
  color: var(--primery-color);
}

.input_wrapper input {
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 2px solid var(--text-color);
  padding: 0 5px;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  background-color: transparent;
  color: #fff;
}

.input__text {
  position: absolute;
  left: 5px;
  bottom: 3px;
  font-weight: 400;
  transition: all ease 0.3s;
  color: #fff;
}

input:focus ~ .input__text,
input:valid ~ .input__text {
  transform: translateY(-35px);
  font-size: 14px;
}

.remember-forgot-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 2rem;
}

.remember-forgot-wrapper .remember-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.remember-forgot-wrapper input {
  accent-color: var(--primery-color);
}

.remember-wrapper .remember-text {
  cursor: pointer;
}

.remember-forgot-wrapper .forgot-link {
  color: var(--primery-color);
  transition: all ease 80ms;
}

.remember-forgot-wrapper .forgot-link:hover {
  color: var(--hover-color);
}

.text-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  gap: 0.7rem;
}

.text-wrapper span {
  width: 80px;
  height: 2px;
  background-color: var(--text-color);
  border-radius: 30px;
}

.social-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-block: 2rem;
}

.social-icons-wrapper a {
  color: var(--text-color);
  transition: all ease 0.8ms;
}

.social-icons-wrapper a i {
  font-size: 2rem;
}

.social-icons-wrapper a:hover {
  color: var(--primery-color);
}

.login-form-btn {
  width: 100%;
  height: 40px;
  border-radius: 30px;
  background-color: var(--primery-color);
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
  transition: all ease 80ms;
}

.form-btn:hover {
  background-color: var(--hover-color);
}

.link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.link-wrapper p {
  font-size: 14px;
}

.link-wrapper .link-text {
  color: var(--text-color);
}

.link-wrapper a {
  color: var(--primery-color);
  transition: all ease 0.8ms;
}

.link-wrapper a:hover {
  color: var(--hover-color);
}

.google-icon {
  font-size: 24px;
}

.check-tik {
  font-size: 22px;
}

.privacy-policy {
  color: #fff;
  margin-left: 10px;
}

.privacy {
  color: var(--primery-color);
}

.privacy-policy-section {
  margin-bottom: 15px;
}

.link-text-p {
  color: #fff;
  margin-top: 15px;
}

.login-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.link-text-p {
  margin: 0;
  color: #fff;
  margin-top: 15px;
}

.login-link {
  margin-top: 15px;
  text-decoration: none;
  color: var(--primery-color);
}

/* .login-form-wrapper {
    display: none;
} */

.border-test {
  border: solid 1px #2ecc71;
  height: 500px;
  width: 500px;
}
/*--------------register--------------*/

.license-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.license-wrapper input {
  accent-color: var(--primery-color);
  cursor: pointer;
}

.license-wrapper label {
  color: var(--text-color);
  cursor: pointer;
}

.license-wrapper label a {
  color: var(--primery-color);
  text-decoration: none;
}

.license-wrapper label a:hover {
  color: var(--hover-color);
}

.register-form .input-wrapper:last-child {
  margin-block: 0;
}

.confirm-password-wrapper {
  margin-block: 1.5rem;
}

.license-wrapper {
  margin-bottom: 1.5rem;
}

/* .form-wrapper {
    display: none;
} */

.container #login:checked ~ .login-register-wrapper .login-form-wrapper,
.container #register:checked ~ .login-register-wrapper .register-form-wrapper {
  display: flex;
}

/*=========Making-Responsive================*/

@media screen and (max-width: 800px) {
  .video-wrapper {
    display: none;
  }

  .form-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .login-register-wrapper {
    width: 90%;
  }

  .form-wrapper form {
    padding: 2rem;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .login-register-wrapper {
    width: 90%;
  }

  .form-wrapper form h1 {
    font-size: 1.5rem;
  }

  .link-wrapper,
  .remember-forgot-wrapper,
  .license-wrapper label,
  .input__text,
  .text-wrapper {
    font-size: 0.8rem;
  }

  .text-wrapper {
    gap: 0.5rem;
  }

  .text-wrapper span {
    width: 60px;
  }

  .social-icons-wrapper a i {
    font-size: 1.5rem;
  }
}
