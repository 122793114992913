


/*======container==============*/

.artist-profile-container {
    max-width: 1300px;
    margin: 0 auto;
}

/*==========background-lights==========*/

.background-lights {
    z-index: -1;
    background-color: var(--banner-img-circles-color);
    border-radius: 50%;
}


.artist-profile-boxes-background-light {
    position: fixed;
    width: 600px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(110px);
}


/*=======header-section=================*/

header {

    position: relative;
}

/*--background-light---*/

.background-light {
    background-color: var(--banner-img-circles-color);
    position: absolute;
}

/*--header-background---*/

.header-background-light {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: -1;
    filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
    background-color: var(--background-color);
    padding: 10px 0;
    box-shadow: 0 0 10px #000;
    position: sticky;
    top: 0;
    z-index: 1000;
}


.navigation {
    display: flex;
    align-items: center;
    width: 99%;
    margin: 0 auto;
    gap: 2rem;
    justify-content: space-between;

}



.nav-left-wrapper a {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
}



.nav-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 1rem;
}


.nav-list-wrapper {
    width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
    justify-content: center;
    gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
}

.nav-right-wrapper .menu li a {
    color: var(--text-color);
    transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
    color: var(--primery-color);
}


.nav-search-and-link-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}


.nav-search-and-link-wrapper form {
    width: 95%;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--primery-color);
    border-radius: 5px;
    position: relative;
}


.nav-search-and-link-wrapper .search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    background-color: transparent;
    margin-left: 1rem;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
    position: absolute;
    width: 30px;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
    font-size: 1.1rem;
}



.nav-link-wrapper {
    display: flex;
    align-items: center;
    width: 15%;
    gap: 1rem;
    justify-content: space-between;
}

.shop-btn {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primery-color);
    border-radius: 5px;
    color: var(--text-second-color);
    font-size: 1.2rem;
    transition: all ease 0.3s;
}

.nav-link-btns:hover {
    background-color: rgb(91, 91, 5);
}



/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
    position: relative;
}

.select-profile {
    height: 36px;
    width: 36px;
    background: var(--primery-color);
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 1.3rem;
    font-weight: 300;
}


.dropdown-profile-mobile-size-item {
    display: none;
}



.select-profile:hover {
    background: rgb(91, 91, 5);
}



/* 
Rotate styles
(add later in javascript)
*/



.drop-menu-profile {
    list-style: none;
    padding: 0 0.1rem;
    background: #ffffffdd;
    border: 1px solid #353a43;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: #000;
    position: absolute;
    top: 3em;
    top: 100%;
    left: -188%;
    width: 200px;
    text-align: center;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
    background-color: var(--background-color);
}


.drop-menu-profile li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1rem;
    background-color: var(--text-color);

}

.drop-menu-profile li a {
    font-size: 1.1rem;
    color: var(--text-second-color);
    font-weight: 500;
}

.drop-menu-profile li:hover {
    background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
    background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/


.drop-menu-profile-open {
    display: block;
}

/*profile-drop-down-btn-end*/


/*---navigation-end----*/



/*============artist-profile-title-buttons-section===========*/

.artist-profile-title-buttons-section {
    margin-top: 3rem;
}


.artist-profile-title-buttons-content-wrapper {
    width: 87%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.artist-profile-title {
    font-size: 2rem;
    color: var(--text-color);
    font-weight: 600;
}

.artist-profile-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.artist-profile-buttons-wrapper button {
    width: 180px;
    height: 45px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid var(--primery-color);
    background-color: transparent;
    color: var(--text-color);
    transition: all ease 0.3s;
}

.artist-profile-buttons-wrapper button:hover {
    background-color: var(--primery-color);
    color: var(--text-second-color);
}

.artist-profile-title-buttons-underline {
    display: inline-block;
    width: 100%;
    height: 1px;
    border-radius: 30px;
    background-color: var(--primery-color);
    margin-top: 3rem;
}

/*=============artist-profile-boxes-section=================*/

.artist-profile-boxes-section {
    margin-top: 3rem;
}

.artist-profile-boxes-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    width: 90%;
    margin: 0 auto;
}

.artist-profile-box {
    justify-self: center;
    margin: 0 auto;
    margin-left: 4.5rem;
    width: 470px;
    height: 150px;
    background: var(--artist-box-color);
    position: relative;
    border-top-right-radius: 40px;
    transition: all ease 0.5s;
    align-self: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.artist-profile-box:hover {
    transform: translateY(-10px);
}

.artist-profile-img-wrapper {
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: absolute;
    left: -66px;
}

.artist-profile-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.artist-profile-caption-wrapper {
    min-height: 100%;
    margin-left: 4rem;
    padding: 1.5rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;

}

.artist-profile-name-see-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.artist-profile-name {
    font-size: 1.3rem;
    color: var(--text-color);
    font-weight: 600;
}

.artist-profile-see-more-button {
    border: 1px solid var(--primery-color);
    padding: 0.3rem 0.9rem;
    font-size: 0.7rem;
    background: #f1c40f2f;
    color: var(--text-color);
    border-radius: 5px;
    transition: all ease 0.3s;
}

.artist-profile-see-more-button:hover{
    background-color: var(--primery-color);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    color: var(--text-second-color);
}


.artist-profile-owner-volume-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.artist-profile-owner-title,
.artist-profile-volume-title {
    color: var(--primery-color);
    font-size: 1rem;
}

.artist-profile-owner-collection-number-box,
.artist-profile-Volume-price-box {
    width: 150px;
    border: 1px solid var(--primery-color);
    padding-block: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 5px;
    color: var(--text-color);
}



/*===============footer=============*/


.footer {
    margin-top: 15rem;
    position: relative;
    z-index: 2000;

}

.footer-wrapper {
    border-top: 20px solid var(--primery-color);
    width: 100%;
    background-color: #212121;
    padding: 1rem;
    box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


.footer-left-wrapper {
    padding: 3rem;
    width: 30%;
}



.social-links-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 3rem;
    flex-wrap: wrap;
}

.social-links-wrapper a {
    color: #b0b0b0;
}


.social-links-wrapper a:hover {
    color: var(--primery-color);
}

.footer-left-wrapper strong {
    display: inline-block;
    font-size: 2rem;
    color: #7E4087;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}

.footer-left-wrapper p {
    max-width: 350px;
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;

}

.footer-right-wrapper {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.footer-right-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
    color: var(--primery-color);
}

.footer-right-wrapper ul a {
    color: var(--text-color);
    font-weight: 300;
    font-size: 1.2rem;
    transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
    color: var(--primery-color);
}

/*=========making-responsive============*/


@media screen and (max-width:1280px) {

    .artist-profile-boxes-wrapper {
        flex-direction: column;
        width: 100%;

    }

    .artist-profile-box {
        margin: 0 auto;
    }

    .artist-profile-title-buttons-content-wrapper {
        width: 90%;
    }

}



@media screen and (max-width:1200px) {


    .artist-profile-container {
        max-width: 1200px;
    }

    .navigation {
        width: 95%;
    }


    .dropdown-btn {
        min-width: 140px;
        min-height: 30px;
    }


    .footer-right-wrapper,
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-left-wrapper {
        min-width: 300px;
    }

    .footer-right-wrapper ul {
        min-width: 160px;
        margin: 1rem;
    }


}





@media screen and (max-width:1100px) {



    .nav-list-wrapper {
        display: none;
    }

    .dropdown-profile-mobile-size-item {
        display: block;
    }

    .nav-right-wrapper {
        justify-content: end;
    }

    .nav-search-and-link-wrapper {
        width: 100%;
    }

    .nav-search-and-link-wrapper form {
        width: 70%;
        margin-left: 1rem;
    }

    .nav-link-wrapper {
        gap: 1rem;
        justify-content: center;
    }

}




@media screen and (max-width:650px) {

    .nav-search-and-link-wrapper form {
        display: none;
    }

    .footer-content-wrapper {
        flex-direction: column;
    }

    .select {
        font-size: 1rem;
        padding: 0.5rem;
    }

    .dropdown-btn {
        width: 130px;
        height: 30px;
    }


    .artist-profile-buttons-wrapper button {
        width: 140px;
        height: 35px;
    }

    .artist-profile-box {
        margin-left: 5rem;
    }

}



@media screen and (max-width:600px) {
    .artist-profile-title-buttons-content-wrapper {
        flex-direction: column;
        gap: 1rem;
    }
}


@media screen and (max-width:580px) {
    


    .artist-profile-box {
        margin: 0 auto;
    }

    .artist-profile-box {
        width: 330px;
        height: auto;
        border-radius: 5px;
        border-top-right-radius: 30px;
    }

    .artist-profile-caption-wrapper {
        margin-left: 0;
        padding: 1rem 2rem;
        gap: 3rem;
    }

    .artist-profile-img-wrapper {
        max-width: 100px;
        height: 100px;
        top: -10px;
        left: -20px;
    }

    .artist-profile-name-see-button-wrapper {
        margin-top: 0.6rem;
        margin-left: 3.5rem;
    }

    .artist-profile-name {
        font-size: 1rem;
    }

    .artist-profile-see-more-button {
        padding: 0.2rem 0.3rem;
    }

    .artist-profile-owner-collection-number-box,
    .artist-profile-Volume-price-box {
        width: 125px;
    }

    .artist-profile-owner-title,
    .artist-profile-volume-title,
    .artist-profile-owner-collection-number,
    .aritst-profile-volume-price {
        font-size: 0.9rem;
    }



}



@media screen and (max-width:550px) {

    .nav-left-wrapper a {
        font-size: 1.5rem;
    }



}

@media screen and (max-width:450px){

    .artist-profile-box {
        width: 300px;
    }

    .artist-profile-caption-wrapper {
        padding: 1rem 1rem;
    }

    .artist-profile-name-see-button-wrapper{
        margin-left: 4.5rem;
    }
}
