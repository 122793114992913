

/*--------background------*/

.background-lights {
    z-index: -1;
    border-radius: 50%;
    background-color: var(--banner-img-circles-color);
}

.create-collection-section{
    position: relative;
}

.top-background-light{
    width: 200px;
    height: 200px;
    filter: blur(100px);
    position: absolute;
    top: -100px;
    left: -100px;
}

/*-----------back-btn-----------*/

.back-btn{
    display: flex;
    align-items: center;
    gap: 1rem;
    position:absolute;
    top: 3%;
    left: 3%;
}

.back-btn-link{
    background-color: var(--primery-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: inset rgba(0,0,0,1) 0 0 5px,0 5px 10px rgba(0,0,0,0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.3s;
}

.back-btn-link:hover{
    background-color: var(--text-hover-color);
    box-shadow: 0 0px 0px rgba(0,0,0,0);
}

.back-btn-icon{
    font-size: 1.3rem;
    color: var(--text-second-color);
}

.back-btn-text{
    color: var(--text-color);
    font-size: 1.3rem;
}


/*=============create-collection-section============*/

.create-collection-section{
    margin-top: 1rem;
}

.main-title-wrapper{
    padding: 2rem;
    margin:0 auto 3rem auto; 
    width: 50%;
}
.create-collection-heading-primary{
    font-size: 2rem;
    color: var(--text-color);
    font-weight: 600;
}

.header-text{
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--text-color);
}

.create-collection-content-wrapper{
    width: 70%;
    margin: 0 auto;
}

.create-collection-box-title{
    font-size: 1.3rem;
    color: var(--text-color);
    font-weight: 400;
    margin-bottom: 1rem;
}

.upload-img-box{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid var(--border-color);
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2rem;
}

.upload-shape-box{
    border: 1px dashed var(--border-color);
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    border-radius: 10px;
}

.create-collection-shap-icon{
    color: var(--text-color);
    font-size: 1.3rem;
}

.create-collection-upload-label{
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
    color: var(--text-color);
    margin-bottom: 1rem;
    display: block;
    cursor: pointer;
    transition: all ease 0.3s;
}

.create-collection-upload-label:hover{
    color: var(--primery-color);
}

.create-collection-upload-text{
    color: var(--text-color);
    font-size: .9rem;
}

.create-collection-upload-size-text{
    margin-bottom: .5rem;
}



.create-collection-input-wrapper{
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin-bottom: 2rem;
}

.create-collection-input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--text-color);
    padding: 1rem;
    font-size: 1rem;
}

.block-chain-wrapper{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    justify-content: space-between;
}

.block-chain-btn{
    padding: 1rem;
    width: 30%;
    min-width: 257px;
    min-height: 128px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    transition: all ease 0.5s;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: transparent;
    outline: 0;
    text-align: left;
}

.box-background{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: block;
    z-index: -1;
    background-color: var(--primery-color);
    transition: all ease 0.5s;
}

.block-chain-btn:hover .block-chain-title,
.block-chain-btn:hover .block-chain-text{
    color: var(--text-second-color);
}



.block-chain-btn:hover{
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    transform: translateY(-10px);
    color: var(--text-color);
}

.block-chain-btn:active{
    transform: translateY(0);
}


.block-chain-btn:hover .box-background{
    height: 100%;
}

.block-chain-icon-title-wrapper{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.block-chain-icon-wrapper{
    width: 30px;
    height: 30px;
    background-color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.block-chain-icon{
    color: var(--text-second-color);
    font-size: 1.1rem;
}

.block-chain-title{
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 400;
    transition: all ease 0.5s;
}

.block-chain-text{
    color: var(--text-color);
    transition: all ease 0.5s;
}

.see-more-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

.see-more-btn-icon-title-wrapper{
    margin: 0;
}

.create-btn-wrapper{
    text-align: end;
    margin-bottom: 3rem;
}

.create-btn{
    width: 180px;
    height: 45px;
    background-color: var(--primery-color);
    color: var(--text-second-color);
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease .3s;
    box-shadow: inset rgba(0,0,0,1) 0 0 5px,0 5px 10px rgba(0,0,0,0.2);
    margin-bottom: 25px;
}

.create-btn:hover{
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--border-color);
}

/*==========making-responsive=============*/

@media screen and (max-width:1200px){
    .contaienr{
        max-width: 1200px;
    }

}

@media screen and (max-width:1151px){
    .main-title-wrapper {
        padding: 7rem 2rem 2rem 2rem;
    }

    .block-chain-wrapper{
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width:750px){
    .main-title-wrapper{
        width: 90%;
    }

    .create-collection-content-wrapper{
        width: 90%;
    }
}