:root {
  --background-color: #333333;
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-img-light-color: radial-gradient(
    50% 50% at 50% 50%,
    rgba(234, 57, 210, 0) 0%,
    rgba(241, 196, 15, 0.8) 0%,
    rgba(234, 57, 210, 0.168) 0.01%,
    rgba(48, 192, 209, 0) 100%
  );
  --text-color: #fff;
  --text-second-color: #000;
  --banner-img-circles-color: #7e4087;
  --text-third-color: #7e7e7e;
  --text-hover-color: #b19310;
  --artist-box-color: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.27) 100%
  );
}

/*=============importing fonts======================*/

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: #333333;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/*======container==============*/

.container {
  max-width: 1300px;
  margin: 0 auto;
}

/*=======header-section=================*/

header {
  position: relative;
}

/*---navigation---*/

.logout-icon {
    color: green;
    
}

.logout-icon-left{
  margin-left: 16px;
}

.home-container-account {
    display: flex;
    align-items: center;
}

.header-top-section {
  background-color: #212121;
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*---header-banner----*/

.circle-section-container {
  margin-top: 5%;
  margin-right: 5%;
}

.content-wrapper {
  display: flex;
  justify-content: space-between; /* تنظیم فاصله بین عناصر */
}

.text-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%; /* اختصاص دادن 50% از عرض به هر کانتینر */
}

/* Text Box  */

.text-box {
  /* margin-top: 5rem; */
  width: 600px;
  height: 250px;
  color: var(--text-color);
  /* padding-left: 6rem; */
}

.text-box h1 {
  font-size: 3.3rem;
  font-weight: 200;
  line-height: 5rem;
}

.text-box p {
  max-width: 400px;
  font-size: 1rem;
  font-weight: 400;
  /* margin-top: 1rem; */
  line-height: 30px;
}

.home-link-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-start;
}

.home-link-wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 45px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
  border-radius: 5px;
  transition: all ease 0.3s;
}

.home-link-wrapper .export {
  background: var(--primery-color);
  color: var(--text-second-color);
  border: none;
}

.home-link-wrapper .export:hover {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--primery-color);
}

.home-link-wrapper .create {
  background: transparent;
  border: 1px solid var(--primery-color);
}

.home-link-wrapper .create:hover {
  background-color: var(--primery-color);
  color: var(--text-second-color);
}

/* Text Box End */

/* circle-section */
.main-circle {
  border-radius: 100%;
  border: 1px solid var(--banner-img-circles-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 550px;
  height: 550px;
  overflow: hidden;
  z-index: -1;
  /* right: 5%; */
  top: -50%;
}

.circle {
  border-radius: 100%;
  border: 1px solid var(--banner-img-circles-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-1,
.circle-2,
.circle-3 {
  position: absolute;
  z-index: -1;
  /* top: 11%; */
}

.circle-1 {
  width: 450px;
  height: 450px;
}

.circle-2 {
  width: 350px;
  height: 350px;
}

.circle-3 {
  width: 250px;
  height: 250px;
}

/* circle-section End */

/* shahab */

header .container {
  position: relative;
}

.main-circle img {
  /* position: absolute; */
  bottom: 5px;
  width: 750px;
  height: 550px;
  /* margin-top: 100px; */
  margin-right: 50px;
}

.header-background-light {
  width: 200px;
  height: 200px;
  background-color: var(--banner-img-circles-color);
  position: absolute;
  z-index: -1;
  left: 10%;
  top: -100%;
  border-radius: 50%;
  filter: blur(100px);
}

.img-background-light {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
  filter: blur(60px);
}

/*---header-banner-end----*/

/*=======header-section-end=================*/

/*=======main-section=======================*/

.main {
  margin: 0 auto;
}

/*=======Shop-section=======================*/

.shop-filters-wrapper {
  /* margin: 30rem auto; */
  margin-top: -150px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.1rem;
  /* margin-bottom: 1rem; */
}

#shop-view-all-link {
  display: inline-block;
  width: 140px;
  text-align: center;
}

/* shahab Drop down  */

.custom-dropdown {
  min-width: 180px;
  height: 45px;
  position: relative;
  
}

.dropdown-header {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  position: absolute;
  width: 100%;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  top: 3.5em;
  transition: 0.2s;
  z-index: 1;
}

.dop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  position: absolute;
  top: 3em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
}

.dropdown-list li:hover {
  background-color: #fff;
}

/*===========shop-drop-down-btns-end===================*/

/* products-container */

.products-container {
  width: 100%; /* عرض کانتینر */
  overflow-x: auto;
  white-space: nowrap; /* جلوگیری از شکستن خطوط */
  padding: 20px; /* فاصله اطراف */
  /* margin: 0 auto; */
}

/* مخفی کردن نوار اسکرول برای وبکیت (کروم، سافاری، و غیره) */
.products-container::-webkit-scrollbar {
  display: none;
}

.shop-product-items-wrapper {
  display: flex;
  gap: 20px; /* فاصله بین محصولات */
}

.shop-product-items-wrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.shop-product-items-wrapper::-webkit-scrollbar{
  display: none;
}

.shop-product-box {
  margin: 1rem;
  width: 250px;
  height: 400px;
  flex: 1;
  text-align: center;
  margin: 0 20px;
}

.shop-product-box {
  width: 312px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
  flex: 1;
  box-shadow: 0 0 10px #000000a8;
}

.shop-product-box-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* End */

/* Shop-slider */

.left-product-icon {
}

.right-product-icon {
  border: solid 2px #f1c40f;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  font-size: 50px;
  padding: 5px;
  margin-left: 20px;
  color: #f2f2f2;
}

/* End */

.dropdown-btn {
  min-width: 180px;
  height: 45px;
  position: relative;
  margin: 1rem;
}

.select {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

/*clicked
(add later in javascript)
*/

.select-clicked {
  border: 2px #26489a;
  box-shadow: 0 0 5px var(--text-second-color);
}

.select:hover {
  background: var(--text-hover-color);
}

.cart {
  font-size: 1.3rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.cart-rotate {
  transform: rotate(180deg);
}

.dop-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}

.dop-menu li:hover {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.dop-menu-open {
  display: block;
}

/*===========shop-drop-down-btns-end===================*/

.shop-section {
  margin-top: 23rem;
  margin-bottom: 15rem;
  width: 100%;
}

.shop-slider-container {
  position: relative;
}

.shop-background-light,
.recommention-background-light,
.artist-background-light,
.category-background-light,
.join-community-background-light {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 100px;
  border-radius: 50%;
  filter: blur(90px);
  background-color: var(--banner-img-circles-color);
}

.shop-product-items-wrapper a {
  transition: all ease 0.3s;
}

.shop-product-items-wrapper a:hover {
  transform: translateY(-20px);
}

.shop-product-items-wrapper::-webkit-scrollbar {
  display: none;
}

.shop-product-box-img-wrapper {
  width: 100%;
  height: 100%;
}

.shop-product-box-caption {
  position: absolute;
  bottom: 0;
  background-color: #ffffffc7;
  width: 100%;
  height: 65px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-second-color);
}

.shop-product-creator {
  font-size: 0.9rem;
  margin-block: 0.3rem;
}

.shop-product-box-caption .caption-deatails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*=======Shop-section-end=======================*/

/*=======recommendetion=======================*/

.labels-wrapper {
  margin: 0 auto;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  color: var(--text-color);
  border: 1px solid var(--primery-color);
  position: relative;
  overflow: hidden;
}

.labels-wrapper .input_label {
  font-size: 1.3rem;
  font: 500;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all ease 0.3s;
  width: 300px;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
}

.labels-wrapper .input_label:hover {
  background-color: rgba(255, 255, 0, 0.1);
  height: 100%;
}

.active_box {
  width: 300px;
  height: 100%;
  background-color: var(--primery-color);
  border-radius: 10px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  transition: all ease 0.3s;
}

.popular-products-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 0.1rem;
  margin-left: 20px;
  width: 100%;
}

.popular-products-container::-webkit-scrollbar {
  display: none;
}

.product-box-container {
  display: flex;
  align-items: center;
  gap: 5rem;
  height: 600px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-inline: 1rem;
  width: 80%;
  margin: 0 auto;
}

.container #popular-products:checked ~ .labels-wrapper .popular-products,
.container #trending:checked ~ .labels-wrapper .trending,
.container #top:checked ~ .labels-wrapper .top {
  color: var(--text-second-color);
  background-color: var(--primery-color);
}

.container #popular-products:checked ~ .labels-wrapper .active_box {
  left: 0px;
}

.container #trending:checked ~ .labels-wrapper .active_box {
  left: 450px;
}

.container #top:checked ~ .labels-wrapper .active_box {
  left: 900px;
}

.recommendation-option {
  display: none;
}

.container input#popular-products:checked ~ .popular-section,
.container input#trending:checked ~ .trending-section,
.container input#top:checked ~ .top-section {
  display: block;
}

/*=======popular-option=======================*/

.recommendation-option {
  padding: 0 3rem;
}

.product-contanir {
  width: 100%;
  position: relative;
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  z-index: 1;
  transition: all ease 0.5s;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.slider-btn i {
  color: var(--primery-color);
  font-size: 1.5rem;
}

.slider-btn-border {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  border: 2px solid var(--primery-color);
  top: 0;
  transition: all ease 0.2s;
  transform: rotate(40deg);
}

.perv-btn-border {
  border-right: none;
  border-top: none;
}

.next-btn-border {
  border-left: none;
  border-bottom: none;
}

/*----------shop-btns------------*/

.perv-btn {
  left: 0;
  left: 50px;
}

.next-btn {
  right: 0;
  right: 50px;
}

.slider-btn:hover {
  /* transform: scale(1.2); */
}

.slider-btn:hover .slider-btn-border {
  animation: border-rotate ease 0.2s;
}

@keyframes border-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.product-box-container {
  display: flex;
  align-items: center;
  gap: 5rem;
  height: 600px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-inline: 1rem;
  width: 80%;
  margin: 0 auto;
}

.product-contanir .product-box-container::-webkit-scrollbar {
  display: none;
}

.input-select-section {
  margin-top: -50px;
}

.product-box {
  min-width: 250px;
  padding: 0.6rem 0.8rem;
  background-color: var(--text-color);
  border-radius: 5px;
  transition: all ease 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 25px;
}

.product-box:hover {
  transform: scale(1.1);
}

.product-box .img-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-box img {
  width: 100%;
  height: 250px;
  object-position: center;
  margin-bottom: 1rem;
}

.caption-title-and-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.caption-title-and-price-wrapper strong,
.caption-title-and-price-wrapper span {
  font-size: 0.7rem;
  font-weight: 800;
  color: var(--text-second-color);
}

.caption-creator-and-view-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.caption-creator-and-view-button-wrapper span {
  color: var(--text-third-color);
  font-size: 0.7rem;
}

.caption-creator-and-view-button-wrapper .view-btn {
  border-radius: 5px;
  border: none;
  background: var(--primery-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-second-color);
  width: 90px;
  padding: 5px;
  font-size: 0.8rem;
  font-weight: 500;
}

.recommendation-section {
  position: relative;
}

/*==========view-all-btn==========*/

.view-all-wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 auto;
  padding-inline: 2rem;
}

.view-all-link {
  color: var(--primery-color);
  font-size: 1.2rem;
  font-weight: 500;
  display: inline-flex; /* اطمینان از قرارگیری متن و آیکون در یک خط */
  align-items: center; /* مرکزی کردن آیتم‌ها در راستای عمودی */
  gap: 8px;
}

.view-all-link:hover {
  color: var(--text-hover-color);
}

/*----section-line-----*/

.section-line {
  width: 85%;
  position: absolute;
  height: 2px;
  background-color: var(--primery-color);
  bottom: -30px;
  right: 90px;
}

/*========recommendetion-end==============*/

/*=========Artist-Profiles-section===========*/

.artist-profile-section {
  margin: 15rem auto;
  width: 90%;
  position: relative;
}

.artist-profile-section .view-all-wrapper {
  width: 100%;
}

.artist-section-title-link-wrapper {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding-left: 80px;
  width: 90%;
  margin: 0 auto;
}

.artist-section-h4 {
  margin-left: 80px;
}

.artist-section-title-link-wrapper h4 {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 500;
}

.artist-section-title-link-wrapper .artist-link-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.artist-section-title-link-wrapper .artist-link-wrapper a {
  color: var(--text-color);
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 45px;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all ease 0.3s;
}

.artist-section-title-link-wrapper .artist-link-wrapper a:hover {
  background-color: var(--primery-color);
  color: var(--text-second-color-color);
}

.artist-profile-slider-wrapper {
  margin-top: 7rem;
  margin-bottom: 1rem;
}

.artist-profiles-box-contaienr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: auto;
  gap: 1rem;
}

.artist-profiles-box-contaienr::-webkit-scrollbar {
  display: none;
}

.artist-profiles-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 100%;
  height: 100%;
  gap: 1.3rem;
}

.profile-box {
  min-width: 350px;
  height: 150px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 5px;
}

.profile-box .profile-img-box {
  width: 145px;
  height: 145px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 5px var(--text-second-color);
  position: absolute;
}

.profile-box .profile-img-box img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.profile-box .profile-text-wrapper {
  background: var(--artist-box-color);
  margin-left: 4rem;
  width: 80%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-left: 6rem;
  color: var(--text-color);
  border-radius: 0px 40px 0px 0px;
  box-shadow: 0 0 5px var(--text-second-color);
}

/* .profile-name-and-price {
  line-height: 2.5rem;
} */

.profile-box .profile-text-wrapper .profile-name {
  font-size: 1.3rem;
}

.profile-box .profile-text-wrapper .profile-price {
  color: #b0b0b0;
}

.artist-background-light {
  top: 60%;
}

.artist-profiles-slider-btn {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  color: var(--primery-color);
  cursor: pointer;
  transition: all ease 0.8ms;
}

#artist-perv-btn {
  left: 0px;
  top: 58%;
}

#artist-next-btn {
  right: 0px;
  top: 58%;
}

.artist-profiles-slider-btn:hover {
  color: var(--text-hover-color);
}

/*=========Artist-Profiles-section-ends===========*/

/*=================Category-banner-section======================*/

.category-banner-section {
  margin: 6rem auto;
  position: relative;
}

.category-banner-light {
  position: absolute;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  z-index: -1;
}

.category-background-light-left {
  top: 10%;
  left: 10%;
  filter: blur(100px);
}

.category-background-light-right {
  top: 70%;
  right: 5%;
  filter: blur(100px);
}

.category-banner-section-content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-banner-cards-wrapper {
  max-width: 40%;
  min-width: 480px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.category-banner-card {
  width: 250px;
  height: 400px;
  background-color: #212121;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px #000;
}

.category-banner-img-wrapper {
  width: 100%;
  height: 85%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: 0 0 5px #000;
}

.category-banner-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-text-wrapper {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
}

.category-banner-cards-wrapper .card-1 {
  transform: rotate(-13deg);
  z-index: 1;
  left: 40px;
  position: absolute;
}

.category-banner-cards-wrapper .card-2 {
  transform: rotate(7deg);
}

.category-banner-text-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.category-banner-text-box {
  max-width: 550px;
}

.category-banner-text-box h4 {
  font-size: 3rem;
  font-weight: 200;
  color: var(--text-color);
  margin-bottom: 5rem;
}

.category-banner-explore-link {
  background-color: var(--primery-color);
  color: var(--text-second-color);
  width: 185px;
  height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all ease 0.8ms;
}

.category-banner-explore-link:hover {
  background-color: var(--text-hover-color);
}

/*=================Category-banner-section-ends======================*/

/*=================Category-section======================*/

.category-section {
  width: 100%;
  margin-block: 10rem;
  position: relative;
}

.category-section-title {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 400;
  width: 90%;
  margin: 0 auto;
}

.category-section-slider-item-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 500px;
  overflow-x: auto;
  gap: 0.9rem;
  scroll-behavior: smooth;
}

.category-section-slider-item-wrapper::-webkit-scrollbar {
  display: none;
}

.category-section-slider-item {
  width: 350px;
  height: 350px;
  position: relative;
  border: 5px;
  background-color: #212121;
  border-radius: 5px;
}

.category-section-img-boxes {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.top-section {
  height: 50%;
  border-radius: 10px;
  overflow: hidden;
}

.bottom-section {
  height: 50%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bottom-section .left-img-wrapper {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.bottom-section .right-img-wrapper {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.bottom-section .right-img-wrapper img,
.bottom-section .left-img-wrapper img,
.top-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.category-section-imgs-caption {
  position: absolute;
  background-color: rgba(99, 99, 99, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--text-color);
}

.category-section-imgs-caption p {
  font-size: 1rem;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid var(--text-color);
  border-radius: 5px;
}

.category-section-slider-item-wrapper a {
  transition: all ease 0.4s;
  margin-inline: 1.5rem;
}

.category-section-slider-item-wrapper a:hover {
  transform: translateY(-20px);
}

.category-next-perv-btn-container {
  position: relative;
  max-width: 90%;
  margin: 0 auto;
}

.category-section-back-ground-light,
.join-community-backgroun-light {
  border-radius: 50%;
  position: absolute;
  width: 900px;
  height: 350px;
  filter: blur(40px);
  background: var(--background-img-light-color);
  z-index: -1;
  top: 30%;
  left: 20%;
  display: none;
}

/*=================category-section====================*/

/*=================join-community-section====================*/

.join-community-section {
  width: 90%;
  margin: 15rem auto;
}

.join-community-content-wrapper {
  display: flex;
  border: 1px solid;
  width: 100%;
  height: 400px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: var(--text-color);
  padding: 2.3rem;
  position: relative;
}

.join-community-text-input-container {
  width: 100%;
}

.join-community-text-input-container strong {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
}

.join-community-text-input-container p {
  font-size: 1.4rem;
  font-weight: 100;
  max-width: 720px;
  color: #ffffff8f;
}

.join-community-text-input-wrapper {
  margin-top: 3rem;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.join-community-content-wrapper input {
  width: 80%;
  height: 100%;
  outline: none;
  border: none;
  padding: 1rem;
  font-size: 1rem;
}

.join-community-content-wrapper button {
  width: 20%;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--primery-color);
  font-size: 1rem;
  font-weight: 500;
}

.join-community-content-wrapper button:hover {
  background-color: var(--text-hover-color);
}

.join-community-text {
  width: 30%;
  height: 100%;
}

.join-community-text p {
  font-size: 2rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
}

.join-community-backgroun-light {
  top: 10%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

/*==========join-community-section-ends==============*/

/*==========footer-==============*/

.footer {
  margin-top: 1rem;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------------------making-responsive------------*/

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  /*header-responsive*/

  .main-circle {
    display: none;
  }

  .circle-section-container {
    display: none;
  }

  .text-box {
    margin: 10rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    text-align: center;
    gap: 0;
  }

  .text-box-container {
    width: 90%;
  }

  .content-wrapper {
    justify-content: center;
  }

  .link-wrapper {
    margin-left: 0;
  }

  .text-box p {
    margin-left: 0;
  }

  /*main-responsive*/

  .main {
    margin: 10rem auto;
  }

  /*shop-responsive*/

  .shop-section {
    margin-top: 3rem;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
    margin: 1rem;
  }

  #shop-view-all-link {
    width: 140px;
  }

  .shop-product-items-wrapper {
    display: flex;
    align-items: center;
    overflow-y: auto;
  }

  .shop-product-box {
    margin: 1rem;
    width: 250px;
    height: 400px;
    flex: 1;
    text-align: center;
    margin: 0 20px;
  }

  /* Scroll */

  .popular-products-container {
    display: flex;
    overflow-x: hidden;
    scroll-behavior: smooth;
    transition: transform 0.3s ease-out;
  }

  /*recommention-responsive*/

  .product-slider-wrapper .slider-btn {
    display: none;
  }

  .product-box-container {
    gap: 2rem;
  }

  /*join-community-responsive*/

  .join-community-content-wrapper {
    flex-wrap: wrap;
    height: auto;
  }

  .join-community-img {
    height: 50px;
    width: 150px;
  }

  /*footer-responsive*/

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }

  .artist-section-h4 {
    margin-left: 0;
  }

  .join-community-text{
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .artist-section-title-link-wrapper {
    padding: 0;
  }
  .shop-section {
    margin-top: 10rem;
  }

  .nav-list-wrapper {
    display: none;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }

  .link-wrapper {
    justify-content: center;
  }

  .product-slider-wrapper {
    width: 100%;
  }

  .product-box-container {
    width: 100%;
  }

  .search-wrapper {
    width: 50%;
  }

  .category-banner-section-content-wrapper {
    flex-direction: column;
    gap: 5rem;
  }

  .join-community-content-wrapper {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .category-background-light-left {
    top: 10%;
    left: 50%;
    filter: blur(50px);
  }

  .category-background-light-right {
    top: 70%;
    right: 30%;
    filter: blur(70px);
  }

  .shop-background-light,
  .recommention-background-light,
  .artist-background-light {
    width: 500px;
  }

  .category-banner-text-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .category-banner-explore-link {
    margin: 0 auto;
  }
}

@media screen and (max-width: 990px) {
  .recommendation-option {
    padding: 0;
  }

  .labels-wrapper {
    width: 100%;
  }

  .view-all-wrapper {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .section-line {
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .artist-link-wrapper a {
    width: 140px;
    height: 40px;
  }

  .category-banner-explore-link {
    margin: 0 auto;
  }

  .join-community-content-wrapper {
    padding: 1rem;
  }

  .join-community-text-input-container strong {
    font-size: 1.9rem;
  }

  .join-community-text-input-container p {
    font-size: 1.5 rem;
  }

  .join-community-content-wrapper input {
    width: 70%;
    height: 100%;
  }

  .join-community-content-wrapper button {
    width: 30%;
  }

  .join-community-text p {
    font-size: 1.5rem;
  }

  .social-links-wrapper {
    font-size: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .slider-btn {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .view-all-wrapper {
    width: 90%;
  }

  .labels-wrapper .input_label {
    width: 150px;
    font-size: 1rem;
  }

  .artist-section-title-link-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }

  .artist-profile-section span {
    display: none;
  }

  .artist-link-wrapper {
    flex-direction: column;
  }

  .category-banner-card {
    width: 200px;
    height: 300px;
  }

  .category-banner-text-box h4 {
    font-size: 2rem;
  }

  .category-banner-cards-wrapper .card-1 {
    left: 133px;
  }
}

@media screen and (max-width: 650px) {
  .text-box {
    margin-top: 40%;
    width: auto;
  }

  .text-box h1 {
    font-size: 2.7rem;
    padding-inline: 1rem;
  }

  .shop-background-light,
  .recommention-background-light,
  .artist-background-light {
    width: 200px;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .join-community-text-input-container {
    width: 100%;
  }

  .join-community-text {
    display: flex;
    width: 100%;
  }

  .join-community-content-wrapper button {
    font-size: 0.9rem;
  }

  .category-banner-text-box h4 {
    font-size: 2rem;
  }

  /*profile artist*/

  .profile-box {
    min-width: 250px;
  }

  .profile-text-wrapper .profile-name {
    font-size: 1.3rem;
    width: 100px;
  }

  .profile-box .profile-text-wrapper {
    border-radius: 5px;
  }
}

@media screen and (max-width: 500px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .join-community-text {
    flex-direction: column;
  }

  .link-wrapper {
    flex-direction: column;
  }

  .nav-search-and-link-wrapper form {
    display: none;
  }

  .select-profile {
    width: 30px;
    height: 30px;
  }

  .shop-btn {
    min-width: 30px;
    height: 30px;
  }

  .shop-filters-wrapper {
    justify-content: center;
  }

  .category-section-slider-item {
    width: 300px;
  }

  .artist-profiles-wrapper {
    margin: 1rem;
  }

  .profile-box .profile-img-box {
    width: 120px;
    height: 120px;
  }

  .profile-box .profile-text-wrapper {
    width: 60%;
    height: 80%;
  }

  .profile-box .profile-name-and-price .profile-name {
    font-size: 1rem;
  }

  .profile-box .profile-name-and-price .profile-price {
    font-size: 0.8rem;
  }

  .shop-background-light,
  .recommention-background-light,
  .artist-background-light,
  .category-background-light,
  .join-community-background-light {
    width: 50px;
    height: 50px;
    filter: blur(50px);
  }

  .category-banner-light {
    width: 50px;
    height: 50px;
  }

  .header-background-light {
    width: 50px;
    height: 50px;
    left: 29%;
  }

  .category-banner-cards-wrapper {
    min-width: 100%;
  }

  .category-banner-cards-wrapper .card-1 {
    left: 25%;
  }

  .category-banner-cards-wrapper .card-2 {
    transform: rotate(10deg);
  }
}

@media screen and (max-width: 350px) {
  .text-box {
    margin-top: 13rem;
  }

  .category-banner-cards-wrapper .card-1 {
    left: 15%;
  }

  .link-wrapper {
    margin-top: 3rem;
    flex-direction: column;
  }
}

/* Berger */

.selected-icon {
  font-size: 1.3rem;
}

.custom-dropdown-berger {
  background: var(--primery-color);
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.custom-dropdown-berger:hover {
  background: rgb(91, 91, 5);
}

.dropdown-header:hover {
  background-color: var(--primery-color);
  color: var(--text-second-color);
}

.dropdown-list-burger {
  background-color: rgba(255, 255, 255, 0.747);
  position: absolute;
  top: 2.5rem;
  left: -8rem;
  border-radius: 10px;
}

.dropdown-list-burger li {
  padding: 0.5rem 1.5rem;
  transition: all ease 0.3s;
  cursor: pointer;
  border-radius: 10px;
}

.dropdown-list-burger li:hover {
  background-color: #fff;
}

.artist-profile-section {
  display: block;
}

.profile-icon-burger {
  margin-top: 8px;
}

/* Cart Changer Style  */

.slider-btn-icon {
  font-size: 2rem;
  color: var(--text-color);
}

.products-container,
.products-container-wrapper {
  position: relative;
}

.perv-btn {
  position: absolute;
  top: 45%;
  left: 1rem;
}

.next-btn {
  position: absolute;
  top: 45%;
  right: 1rem;
}

.recommendation-slider-btn-perv {
  left: 2rem;
}

.recommendation-slider-btn-next {
  right: 2rem;
}

.product-box {
  background: rgb(116, 116, 116);
  cursor: pointer;
}

.caption-creator-and-view-button-wrapper span {
  color: var(--text-color);
}

.shop-view-all-wrapper {
  margin-top: 3rem;
  width: 100%;
}

/* wallet btn */

/* استایل پیش‌فرض دکمه */
.wallet-connected .connect-button {
  background-color: #f1c40f;
  color: #0a0a0a !important;
  cursor: pointer !important;
  font-size: 100% !important;
  height: 45px !important;
  width: 50px;
  padding: 5px 15px !important;
  position: relative !important;
  text-align: center !important;
  border-radius: 5px;
  transition: all 0.15s ease-in-out !important;
}

/* استایل دکمه پس از اتصال کیف پول */
.wallet-connected.connected .connect-button {
  padding: 3px;
  font-size: 14px;
}

.logo-link {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  overflow: hidden;
  display: block;
}

.logo-home {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
