/*===============colors================*/

:root {
  --background-color: #333333;
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-img-light-color: radial-gradient(
    50% 50% at 50% 50%,
    rgba(234, 57, 210, 0) 0%,
    rgba(241, 196, 15, 0.8) 0%,
    rgba(234, 57, 210, 0.168) 0.01%,
    rgba(48, 192, 209, 0) 100%
  );
  --text-color: #fff;
  --text-second-color: #000;
  --banner-img-circles-color: #7e4087;
  --text-hover-color: #b19310;
  --history-item-background: rgb(200, 200, 200);
  --history-item-background-gradient: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --history-date-box-background: rgb(241, 196, 15);
  --history-date-box-background-gradient: linear-gradient(
    -90deg,
    rgba(241, 196, 15, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
}

/*=============importing fonts======================*/

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*============favorite-page-title=================*/

.favorite-page-title-section {
  margin: 3rem auto 1rem auto;
}

.favorite-page-title {
  font-weight: 600;
  font-size: 2.3rem;
  color: var(--text-color);
  width: 78%;
  margin: 0 auto;
}

/*===========favorite-main-background=============*/

.favorite-main-section-background-light {
  position: fixed;
  width: 800px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(110px);
}

/*=========favorite-options-and-button-section=============*/

.favorite-option-and-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96.5%;
  margin: 0 auto;
}

.favorite-options-and-button-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.favorite-option-content-wrapper {
  position: relative;
}

.favorite-option-content-wrapper {
  margin: 0 auto;
  width: 390px;
}

.favorite-options-wrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  padding: 1rem 0;
  color: var(--text-color);
  transition: all ease 0.3s;
  position: relative;
  margin: 0 auto;
}

.favorite-options-wrapper button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  font-size: 1.1rem;
  z-index: 1;
}

.active-box {
  position: absolute;
  background: var(--primery-color);
  bottom: -1.5px;
  height: 2px;
  transition: all ease 0.5s;
  border-radius: 30px;
}

.favorite-options-wrapper button:nth-child(1) {
  width: 150px;
}

.favorite-options-wrapper .active-box-start-all-section,
button:nth-child(1):hover ~ .active-box {
  width: 150px;
  left: 0;
}

.favorite-options-wrapper button:nth-child(2) {
  width: 100px;
}

.favorite-options-wrapper.active-box-collections,
button:nth-child(2):hover ~ .active-box {
  width: 100px;
  left: 154px;
}

.favorite-options-wrapper button:nth-child(3) {
  width: 150px;
}

.favorite-options-wrapper .active-box-artist-profiles,
button:nth-child(3):hover ~ .active-box {
  width: 120px;
  left: 270px;
}

.favorite-options-section-underline {
  width: 90%;
  height: 0.5px;
  background-color: var(--primery-color);
  display: block;
  margin: 0 auto;
  border-radius: 30px;
}

.favorite-sections {
  margin-top: 2rem;
  display: none;
}

.all-items-section {
  display: block;
}

.collection-section {
  display: none;
}

/* .artist-profile-section {
  display: none;
} */

/*=================all-items-section&Collection-section=================*/

.all-items-section,
.collection-section,
.artist-profile-section {
  margin: 2rem auto;
}

.all-items-box-wrapper,
.collection-box-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.all-items-box-section-background-light,
.collection-box-section-background-light {
  height: 100px;
  width: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(110px);
}

/*----------all-items-item-&-collection-item---------*/

.all-items-product-box,
.collection-product-box {
  width: 312px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
  box-shadow: 0 0 10px #000000a8;
}

.all-items-product-box-img-wrapper,
.collection-product-box-img-wrapper {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.all-items-product-box-img-wrapper img,
.collection-product-box-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.all-items-product-box-caption,
.collection-product-box-caption {
  position: absolute;
  bottom: 0;
  background-color: #ffffffc7;
  width: 100%;
  height: 65px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-second-color);
}

.all-items-product-creator,
.collection-product-creator {
  font-size: 0.9rem;
  margin-block: 0.3rem;
}

.all-items-product-box-caption .caption-deatails,
.collection-product-box-caption .caption-deatails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-items-box-wrapper a,
.collection-box-wrapper a {
  transition: all ease 0.4s;
}

.all-items-box-wrapper a:hover,
.collection-box-wrapper a:hover {
  transform: translateY(-20px);
}

/*==========Artist-profile-main-sectin==================*/

.artist-profiles-wrapper {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}

.artist-profiles-wrapper a {
  transition: all ease 0.3s;
}

.artist-profiles-wrapper a:hover {
  transform: translateY(-20px);
}

.artist-profile-main-sectin-background-light {
  width: 800px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(100px);
}

/* .profile-box {
  width: 633px;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
} */

.profile-box .profile-img-box {
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: absolute;
}

.profile-box .profile-img-box img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.profile-box .profile-text-wrapper {
  background: var(--artist-box-color);
  margin-left: 5rem;
  width: 100%;
  height: 100%;
  border-top-right-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 2rem 2rem 2rem 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
}

.profile-name-rank-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--text-color);
}

.profile-price-collection-artwork-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  color: var(--text-fourth-color);
}

/*===============pagination==================*/

.pagination-section {
  margin-block: 5rem;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 85%;
  margin: 0 auto;
}

.pagination-btn {
  background: transparent;
  border: none;
  margin: 1rem;
  font-size: 1.5rem;
  color: var(--primery-color);
  cursor: pointer;
  transition: all ease 0.3s;
}

.pagination-btn:hover {
  color: var(--text-hover-color);
}

.pagination-wrapper ul {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--text-color);
}

.pagination-wrapper ul .link {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all ease 0.3s;
}

.pagination-wrapper ul .link:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

#active {
  background: var(--primery-color);
  color: var(--text-second-color);
}

/*===============footer=============*/

.footer {
  margin-top: 1rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*=====Making-respnsive=================*/

@media screen and (max-width: 1200px) {
  .navigation {
    width: 95%;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 655px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .favorite-container {
    max-width: 100%;
  }

  .artist-profiles-wrapper {
    width: 100%;
  }

  .profile-box {
    width: 100%;
  }

  .profile-text-wrapper {
    gap: 2rem;
    justify-content: center;
  }

  .profile-name-rank-wrapper {
    font-size: 1rem;
    flex-wrap: wrap;
  }

  .profile-price-collection-artwork-wrapper {
    flex-wrap: wrap;
    font-size: 1rem;
    gap: 1rem;
    justify-content: center;
  }

  .favorite-buttons-wrapper button {
    font-size: 1rem;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 550px) {
  .profile-box {
    width: 90%;
    margin: 0 auto;
  }

  .profile-box .profile-text-wrapper {
    margin: 0;
  }

  /* .profile-box .profile-text-wrapper {
    border-radius: 10px;
    border-top-right-radius: 50px;
    width: 300px;
    height: 230px;
    padding-inline: 2rem;
    gap: 2.5rem;
  } */

  .profile-name-rank-wrapper {
    padding-left: 5rem;
  }

  .profile-box .profile-img-box {
    top: -15px;
    left: -15px;
    width: 120px;
    height: 120px;
  }

  .profile-img-box img {
    object-fit: cover;
  }
}

@media screen and (max-width: 480px) {
  .favorite-options-wrapper {
    gap: 0.5rem;
    justify-content: center;
  }

  .favorite-options-wrapper button {
    font-size: 0.9rem;
  }

  .favorite-options-wrapper button:nth-child(1) {
    width: 100px;
  }

  .favorite-options-wrapper .active-box-start-all-section,
  button:nth-child(1):hover ~ .active-box {
    width: 100px;
    left: 8%;
  }

  .favorite-options-wrapper button:nth-child(2) {
    width: 100px;
  }

  .favorite-options-wrapper.active-box-collections,
  button:nth-child(2):hover ~ .active-box {
    width: 90px;
    left: 37%;
  }

  .favorite-options-wrapper button:nth-child(3) {
    width: 110px;
  }

  .favorite-options-wrapper .active-box-artist-profiles,
  button:nth-child(3):hover ~ .active-box {
    width: 110px;
    left: 63%;
  }
}

@media screen and (max-width: 420px) {
  .pagination-wrapper {
    justify-content: center;
  }

  .pagination-wrapper ul {
    font-size: 1rem;
  }

  .pagination-wrapper ul .link {
    width: 30px;
    height: 30px;
  }

  .pagination-btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 395px) {
  .favorite-options-wrapper button:nth-child(1) {
    width: 100px;
  }

  .favorite-options-wrapper .active-box-start-all-section,
  button:nth-child(1):hover ~ .active-box {
    width: 80px;
    left: 5%;
  }

    .favorite-options-wrapper.active-box-collections,
  button:nth-child(2):hover ~ .active-box {
    width: 90px;
    left: 35%;
  }

    .favorite-options-wrapper .active-box-artist-profiles,
  button:nth-child(3):hover ~ .active-box {
    width: 100px;
    left: 66%;
  }
}

@media screen and (max-width: 376px){
    
  .favorite-options-wrapper .active-box-start-all-section,
  button:nth-child(1):hover ~ .active-box {
    width: 80px;
    left: 8%;
  }

}

li{
  text-align: left;
}


