/*===============colors================*/

:root {
  --background-color: #333333;
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-img-light-color: radial-gradient(
    50% 50% at 50% 50%,
    rgba(234, 57, 210, 0) 0%,
    rgba(241, 196, 15, 0.8) 0%,
    rgba(234, 57, 210, 0.168) 0.01%,
    rgba(48, 192, 209, 0) 100%
  );
  --text-color: #fff;
  --text-second-color: #000;
  --banner-img-circles-color: #7e4087;
  --text-hover-color: #b19310;
  --shop-button-background: rgb(200, 200, 200);
  --shop-button-background-gradient: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(255, 255, 255, 0.01) 100%
  );
}

/*=============importing fonts======================*/

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

.shop-filters-and-boxes-section {
  padding-inline: 0.6rem;
}

.container-shop {
  max-width: 1300px;
  margin: 0 auto;
}

.shop-filters-and-boxes-wrapper {
  display: flex;
  margin: 1rem auto;
  gap: 1rem;
  padding-inline: 0.3rem;
}

.shop-drop-button-section {
  max-width: 300px;
}

.shop-drop-button-wrapper {
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.6rem;
}

/* DropDown */

.dropdown-btn-shop {
  width: 220px;
  min-height: 55px;
}

.select-shop {
  background: var(--shop-button-background);
  background: var(--shop-button-background-gradient);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--primery-color);
  border-radius: 2px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 0.9rem;
  font-weight: 300;
  height: 55px;
}

.select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.select-shop:hover,
.select-shop:hover .cart {
  color: var(--primery-color);
}

.cart {
  font-size: 0.8rem;
  color: var(--text-color);
}

.drop-menu-shop {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: var(--shop-button-background);
  background: var(--shop-button-background-gradient);
  border-bottom: 1px solid var(--primery-color);
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  color: var(--text-color);
  /* opacity: 0; */
  /* display: none; */
  transition: 0.2s;
  z-index: 1;
}

.drop-menu-shop li {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0.3rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.drop-menu-shop li input,
.drop-menu-shop li label {
  cursor: pointer;
}

.drop-menu-shop li input {
  accent-color: var(--primery-color);
}

.art-style-drop-menu {
  opacity: 1;
  display: block;
}

/* Shop Box */

.shop-box-wrapper-container {
  max-width: 100%;
}

.shop-box-wrapper-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
}

.shop-box-section-background-light {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  filter: blur(90px);
}

/* .background-light {
    background-color: var(--banner-img-circles-color);
    position: absolute;
} */

.shop-box-wrapper-container a {
  transition: all ease 0.4s;
}

.shop-box-wrapper-container a:hover {
  transform: translateY(-20px);
}

.shop-product-box-container {
  width: 312px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
  box-shadow: 0 0 10px #000000a8;
}

.shop-product-box-img-wrapper-shop {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.shop-product-box-img-wrapper-shop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.shop-product-box-caption-shop {
  position: absolute;
  bottom: 0;
  background-color: #ffffffc7;
  width: 100%;
  height: 65px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-second-color);
}

.shop-product-box-caption-shop .caption-deatails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Pagination */

.pagination-section {
  margin-bottom: 2rem;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 85%;
  margin: 0 auto;
}

.pagination-btn {
  background: transparent;
  border: none;
  margin: 1rem;
  font-size: 1.5rem;
  color: var(--primery-color);
  cursor: pointer;
  transition: all ease 0.3s;
}

.pagination-btn:hover {
  color: var(--text-hover-color);
}

.pagination-wrapper ul {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--text-color);
}

.pagination-wrapper ul .link {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all ease 0.3s;
}

.pagination-wrapper ul .link:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .shop-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .shop-filters-and-boxes-section {
    padding-inline: 0.6rem;
  }

  .shop-filters-wrapper {
    gap: 0;
  }

  .shop-drop-button-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .shop-drop-button-wrapper {
    gap: 2rem;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }

  .shop-drop-button-section {
    max-width: 100%;
  }

  .shop-drop-button-wrapper {
    justify-content: center;
    margin-bottom: 1.6rem;
    display: flex;
  }

  .drop-menu-shop {
    background-color: var(--text-second-color);
    color: var(--text-color);
    position: absolute;
    padding: 0.1rem;
    width:  220px;

  }

  .drop-menu-shop li {
    gap: 1px;
  }

  .art-style-drop-menu {
    opacity: 0;
    display: none;
  }

  .shop-filters-and-boxes-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .shop-filters-and-boxes-wrapper {
    flex-direction: column;
  }

  .search-box {
    margin: 0 auto;
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .pagination-wrapper {
    justify-content: center;
  }

  .pagination-wrapper ul {
    font-size: 1rem;
  }

  .pagination-wrapper ul .link {
    width: 30px;
    height: 30px;
  }

  .pagination-btn {
    font-size: 1.1rem;
  }

  .shop-box-section-background-light {
    width: 200px;
    height: 100px;
  }
}
