:root {
  --background-color: #333333;
  --primery-color: #f1c40f;
  --secondry-color: #2ecc71;
  --background-img-light-color: radial-gradient(
    50% 50% at 50% 50%,
    rgba(234, 57, 210, 0) 0%,
    rgba(241, 196, 15, 0.8) 0%,
    rgba(234, 57, 210, 0.168) 0.01%,
    rgba(48, 192, 209, 0) 100%
  );
  --text-color: #fff;
  --text-second-color: #000;
  --banner-img-circles-color: #7e4087;
  --text-third-color: #4c4b4b;
  --text-hover-color: #b19310;
  --artist-box-color: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.27) 100%
  );
}

/*=============importing fonts======================*/

/* Poppins Black */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-Black.ttf') format('truetype');
}

/* Poppins Black Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

/* Poppins Bold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

/* Poppins ExtraBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
}

/* Poppins ExtraLight */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
}

/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-Thin.ttf') format('truetype');
}

/* Poppins Thin Italic */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
}

/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 99%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*===========Recommendation-title-section===============*/

.recommendation-title-section {
  margin-top: 3rem;
}

.recommendation-title {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 600;
  width: 96.5%;
  margin: 0 auto;
}

/*=========Recommendation-options-and-button-section=============*/

.recommendation-option-and-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96.5%;
  margin: 0 auto;
}

.recommendation-options-and-button-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.recommendation-option-content-wrapper {
  position: relative;
}

.recommendation-option-content-wrapper {
  border-bottom: 1px solid var(--text-hover-color);
  width: 350px;
}

.recommendation-options-wrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  font-size: 1.5rem;
  padding: 1rem 0;
  color: var(--text-color);
  transition: all ease 0.3s;
  position: relative;
  margin: 0 auto;
}

.recommendation-options-wrapper button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  font-size: 1.1rem;
  z-index: 1;
}

.active-box {
  position: absolute;
  background: var(--primery-color);
  bottom: -1.5px;
  height: 2px;
  transition: all ease 0.5s;
}

.recommendation-options-wrapper button:nth-child(1) {
  width: 150px;
}

.recommendation-options-wrapper .active-box-start-top-section,
button:nth-child(1):hover ~ .active-box {
  width: 130px;
  left: 0;
}

.recommendation-options-wrapper button:nth-child(2) {
  width: 100px;
}

.recommendation-options-wrapper.active-box-popular,
button:nth-child(2):hover ~ .active-box {
  width: 100px;
  left: 135px;
}

.recommendation-options-wrapper button:nth-child(3) {
  width: 130px;
}

.recommendation-options-wrapper .active-box-trending,
button:nth-child(3):hover ~ .active-box {
  width: 100px;
  left: 250px;
}

/*===========recommendation-drop-down-btns===================*/

.dropdown-btn {
  min-width: 180px;
  height: 40px;
  position: relative;
}

.select {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

/*clicked
(add later in javascript)
*/

.select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.select:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

.cart {
  font-size: 1.1rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.cart-rotate {
  transform: rotate(180deg);
}

.drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  position: absolute;
  top: 3em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 5;
}

.drop-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}

.drop-menu li:hover {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-open {
  display: block;
}

/*===========recommendation-drop-down-btns-end===================*/

/*===========Recommendation-main-section=============*/

.recommendation-main-section-background-light {
  position: fixed;
  width: 800px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(110px);
}

.recommendation-top-section {
  display: block;
}

.recommendation-popular-section {
  display: none;
}

.recommendation-trending-section {
  display: none;
}

.product-box-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.product-box-container {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.product-box-container {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

/*===========product-boxes===========================*/

.recommendation-box-section {
  margin-top: 2rem;
}

.product-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 99%;
  margin: 0 auto;
  height: auto;
}

.product-box {
  width: 290px;
  padding: 0.6rem 1rem;

  border-radius: 5px;
  transition: all ease 0.4s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.product-box:hover {
  transform: translateY(-20px);
}

.product-box .img-wrapper {
  width: 100%;
  height: 360px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-box img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  margin-bottom: 1rem;
}

.caption-title-and-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.caption-title-and-price-wrapper strong,
.caption-title-and-price-wrapper span {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--text-second-color);
}

.caption-creator-and-view-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.caption-creator-and-view-button-wrapper span {
  /* color: var(--text-third-color); */
  font-size: 0.9rem;
}

/*===============pagination==================*/

.pagination-section {
  margin-block: 5rem;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 85%;
  margin: 0 auto;
}

.pagination-btn {
  background: transparent;
  border: none;
  margin: 1rem;
  font-size: 1.5rem;
  color: var(--primery-color);
  cursor: pointer;
  transition: all ease 0.3s;
}

.pagination-btn:hover {
  color: var(--text-hover-color);
}

.pagination-wrapper ul {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  color: var(--text-color);
}

.pagination-wrapper ul .link {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: all ease 0.3s;
}

.pagination-wrapper ul .link:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

#active {
  background: var(--primery-color);
  color: var(--text-second-color);
}

/*===============footer=============*/

.footer {
  margin-top: 1rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .recommendation-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .recommendation-option-and-button-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 420px) {
  .pagination-wrapper {
    justify-content: center;
  }

  .pagination-wrapper ul {
    font-size: 1rem;
  }

  .pagination-wrapper ul .link {
    width: 30px;
    height: 30px;
  }

  .pagination-btn {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 580px) {
  .recommendation-option-and-button-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
