/* .App {
  text-align: center;
} */


:root {

    --background-color: #333333;
    --primery-color: #F1C40F;
    --secondry-color: #2ECC71;
    --background-img-light-color: radial-gradient(50% 50% at 50% 50%, rgba(234, 57, 210, 0) 0%, rgba(241, 196, 15, 0.8) 0%, rgba(234, 57, 210, 0.168) 0.01%, rgba(48, 192, 209, 0) 100%);
    --text-color: #fff;
    --text-second-color: #000;
    --banner-img-circles-color: #7E4087;
    --text-third-color: #4c4b4b;
    --text-hover-color: #b19310;
    --artist-box-color: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.27) 100%);
    --text-fourth-color: #b0b0b0;
    --text-fifth-color: #c9c9c9;
    --collection-button-background: rgb(200, 200, 200);
    --collection-button-background-gradient: linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0.01) 100%);
    --border-color:rgba(241, 196, 15,.5);
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
