/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/
/*========background-circle============*/

.plan-background-circle {
  border-radius: 50%;
  border: 2px solid var(--banner-img-circles-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-main-circle {
  width: 1500px;
  height: 1500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -5;
}

.plan-second-circle {
  width: 1000px;
  height: 1000px;
}

.third-circle {
  width: 500px;
  height: 500px;
}

.plan-background-light-main {
  background-color: var(--banner-img-circles-color);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(100px);
}
/*==========main==========*/

.main {
  width: 90%;
  margin: 5rem auto;
}
.title-wrapper {
  text-align: center;
  margin-bottom: 5rem;
}

.heading-primary {
  font-size: 2.5rem;
  color: var(--text-color);
  font-weight: 300;
  margin-bottom: 1rem;
}

.heading-paragraph {
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 400;
}

/*-----sort-btns----*/

.sort-button-wrapper {
  border: 1px solid var(--text-third-color);
  width: 10rem;
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  padding: 0.2rem;
  margin-bottom: 2rem;
}
.sort-btn {
  width: 50%;
  padding: 1rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 2rem;
  color: var(--text-color);
  cursor: pointer;
  transition: all ease 0.3s;
}

.sort-btn:hover {
  background-color: var(--primery-color);
  color: var(--text-second-color);
}

.sort-active-btn {
  background-color: var(--primery-color);
  color: var(--text-second-color);
}

/*------card-------*/

.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.card {
  border: 1px solid var(--text-color);
  width: 30%;
  min-width: 330px;
  height: auto;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  padding: 2rem;
}

.card-type-text {
  padding: 0.5rem;
  background: linear-gradient(to bottom, #ffff, #969696);
  border-radius: 10px;
  width: 40%;
  min-width: 120px;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.card-price-lenth-wrapper {
  margin-bottom: 0.5rem;
}

.card-price-lenth {
  font-size: 2rem;
  color: var(--text-color);
  font-weight: 500;
}

.card-description {
  color: var(--text-fourth-color);
  font-size: 1rem;
}

.card-options-wrapper {
  margin-block: 2rem;
}

.card-option-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-option-item {
  color: var(--text-color);
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.card-option-icon {
  color: var(--primery-color);
}

.card-main-button {
  padding: 1rem;
  background-color: var(--text-color);
  border-radius: 2rem;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-second-color);
  font-weight: 600;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  transition: all ease 0.3s;
}

.card-main-button:hover {
  background-color: var(--primery-color);
}

.card-main-button-active {
  background-color: var(--primery-color);
}

/*===============footer=============*/

.footer {
  margin-top: 8rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .history-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .card-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 420px) {
  .history-box-section-background-light {
    width: 200px;
    height: 100px;
  }
}

@media screen and (max-width: 400px) {
  .history-box {
    flex-direction: column;
    height: auto;
    width: 300px;
  }

  .history-box-left-side {
    width: 100%;
  }

  .history-box-title-and-creator-wrapper {
    padding-top: 0;
  }

  .history-box-img-wrapper img {
    border-radius: 10px;
  }

  .history-box-right-side {
    width: 100%;
    gap: 20px;
  }
}

.card-option-item {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Centers items vertically in the line */
}

.card-option-icon {
  /* If needed, adjust size and color of the icon */
  color: var(--primery-color);
  margin-right: 5px;
}
