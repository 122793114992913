.collection-container {
  max-width: 1300px;
  margin: 0 auto;
}

/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*--====header-banner-section========*/

.header-banner-section {
  height: 25rem;
  background-image: url(../assets//images//collection-image.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.header-banner-image-contaienr {
  position: relative;
  height: 100%;
}

.collection-profile-image {
  width: 10rem;
  height: 10rem;
  border-radius: 1.5rem;
  overflow: hidden;
  position: absolute;
  bottom: 23%;
  left: 4%;
  background-image: url(../assets//images/collection-image.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid var(--text-color);
}

.collection-profile-deatails {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  backdrop-filter: blur(10px);
}
.collection-profile-deatails-content-wrapper {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collection-profile-title-and-create-and-item-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.collection-profile-title-wrapper {
  padding: 0 1rem;
  border-right: 2px solid var(--text-color);
}

.collection-profile-title {
  font-size: 2rem;
  font-weight: 500;
  color: var(--text-color);
  letter-spacing: 1px;
}

.collection-profile-create-and-item-wrapper {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.create-time,
.item-number {
  color: var(--text-fifth-color);
  font-weight: 200;
  letter-spacing: 0.3px;
}

.collection-profile-span {
  color: var(--text-color);
  font-weight: 400;
}

.collection-profile-total-and-floor-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  text-align: center;
}
.totla-volume-wrapper,
.floor-price-wrapper {
  width: 30%;
  color: var(--text-fifth-color);
  letter-spacing: 0.3px;
  font-weight: 200;
}

.total-and-floor-price {
  display: inline-block;
  margin-bottom: 0.4rem;
  color: var(--text-color);
  font-weight: 400;
}

/*=======header-banner-deatails==========*/

.caption-and-buttons-section {
  margin: 0 auto;
  margin-top: 2rem;
  padding-inline: 2rem;
}

.caption-and-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-inline: 2rem;
}

.collection-profile-caption-wrapper {
  color: var(--text-color);
  font-size: 1.1rem;
  width: 30%;
  line-height: 2rem;
}

.see-more {
  cursor: pointer;
  transition: all ease 0.2s;
}

.see-more:hover {
  color: var(--text-fourth-color);
}

/*this-class-will-add-later-in-js*/
.rest-of-the-profil-caption {
  display: none;
}

.collection-like-share-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.collection-like-share-buttons-wrapper button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
}

/*===main-section=======*/

.main {
  margin-top: 5rem;
}
/*====search-sort-section======*/

.search-and-sort-wrapper {
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-wrapper {
  width: 70%;
  height: 45px;
  background-color: transparent;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

/*===========profile-drop-down-btns===================*/

.collection-dropdown-btn {
  min-width: 180px;
  height: 40px;
  position: relative;
}

.collection-dropdown-btn-select {
  background: transparent;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--primery-color);
  border-radius: 5px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
  min-width: 180px;
  height: 40px;
}

/*clicked
(add later in javascript)
*/

.collection-dropdown-btn-select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.collection-dropdown-btn-select:hover {
  background: var(--primery-color);
  color: var(--text-second-color);
}

.collection-dropdown-btn-cart {
  font-size: 1.1rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.collection-dropdown-btn-cart-rotate {
  transform: rotate(180deg);
}

.collection-dropdown-btn-drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  color: var(--text-second-color);
  position: absolute;
  top: 3em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 5;
}

.collection-dropdown-btn-drop-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}

.collection-dropdown-btn-drop-menu li:hover {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.collection-dropdown-btn-drop-menu-open {
  display: block;
}

/*===========profile-drop-down-btns-end===================*/

.section-under-line {
  display: block;
  width: 95%;
  margin: 0 auto;
  margin-top: 3rem;
  background-color: var(--primery-color);
  height: 2px;
}

/*===========collection-filters-section==============*/

.collection-filters-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-block: 1rem;
  gap: 3rem;
}

.collection-filters-and-boxes-wrapper {
  display: flex;
  margin: 1rem auto;
  gap: 5px;
  padding-inline: 0.3rem;
}

.collection-drop-button-section {
  max-width: 300px;
}

.collection-box-wrapper {
  max-width: 100%;
}

.collection-drop-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/*===========profile-drop-down-btns===================*/

.dropdown-btn {
  width: 220px;
  min-height: 55px;
}

.select {
  background: var(--collection-button-background);
  background: var(--collection-button-background-gradient);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--primery-color);
  border-radius: 2px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 0.9rem;
  font-weight: 300;
  height: 55px;
}

/*clicked
(add later in javascript)
*/

.select-clicked {
  background: var(--text-hover-color);
  color: var(--text-second-color);
  box-shadow: 0 0 5px var(--text-second-color);
}

.select:hover,
.select:hover .cart {
  color: var(--primery-color);
}

.cart {
  font-size: 0.8rem;
  color: var(--text-color);
}

/* 
Rotate styles
(add later in javascript)
*/

.cart-rotate {
  transform: rotate(180deg);
}

.drop-menu {
  list-style: none;
  padding: 0.2rem 0.5rem;
  background: var(--collection-button-background);
  background: var(--collection-button-background-gradient);
  border-bottom: 1px solid var(--primery-color);
  /* box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3); */
  border-radius: 2px;
  color: var(--text-color);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}

.art-style-drop-menu {
  opacity: 1;
  display: block;
}

.color-drop-menu {
  width: 250px;
}

.drop-menu li {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0.3rem;
  margin: 0.5rem;
  font-size: 0.9rem;
}

.drop-menu li input,
.drop-menu li label {
  cursor: pointer;
}

.drop-menu li input {
  accent-color: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-open {
  display: block;
}

/*===========collection-drop-down-btns-end===================*/

/*=================collection-box-section=================*/

.collection-box-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
}

/*----------collection-item---------*/

.collection-product-box {
  width: 312px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0.6rem;
  box-shadow: 0 0 10px #000000a8;
}

.collection-product-box-img-wrapper {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.collection-product-box-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.collection-product-box-caption {
  position: absolute;
  bottom: 0;
  background-color: #ffffffc7;
  width: 100%;
  height: 65px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-second-color);
}

.collection-product-creator {
  font-size: 0.9rem;
  margin-block: 0.3rem;
}

.collection-product-box-caption .caption-deatails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collection-box-wrapper a {
  transition: all ease 0.4s;
}

.collection-box-wrapper a:hover {
  transform: translateY(-20px);
}

/*===============footer=============*/

.footer {
  margin-top: 5rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .collection-container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .nav-list-wrapper .menu {
    gap: 2rem;
  }

  .collection-filters-and-boxes-section {
    padding-inline: 0.6rem;
  }

  .collection-filters-wrapper {
    gap: 0;
  }

  .collection-drop-button-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }

  .caption-and-buttons-wrapper {
    padding-inline: 0;
  }

  .collection-profile-caption-wrapper {
    min-width: 235px;
  }
}

@media screen and (max-width: 1000px) {
  .collection-profile-deatails-content-wrapper {
    padding: 0.4rem;
  }

  .collection-profile-title-wrapper {
    padding-inline: 0.3rem;
  }
  .collection-profile-title {
    font-size: 0.9rem;
  }

  .collection-profile-total-and-floor-wrapper {
    width: 40%;
  }

  .create-time,
  .item-number,
  .total-volume,
  .floor-price {
    font-size: 0.7rem;
  }

  .collection-profile-create-and-item-wrapper p {
    display: flex;
    flex-direction: column-reverse;
  }

  .collection-profile-image {
    width: 8rem;
    height: 8rem;
  }

  .search-and-sort-wrapper {
    gap: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }

  .collection-drop-button-wrapper {
    gap: 2rem;
  }

  .collection-drop-button-section {
    max-width: 100%;
  }

  .collection-drop-button-wrapper {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .drop-menu {
    background-color: var(--text-second-color);
    color: var(--text-color);
    position: relative;
    padding: 0.1rem;
  }

  .drop-menu li {
    gap: 1px;
  }

  .art-style-drop-menu {
    opacity: 0;
    display: none;
  }
  .search-and-sort-wrapper {
    flex-direction: column;
    gap: 2rem;
  }

  .search-wrapper {
    width: 100%;
  }

  .collection-filters-and-boxes-wrapper {
    flex-direction: column;
  }


}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .collection-filters-and-boxes-wrapper {
    flex-direction: column;
  }

  .search-and-sort-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 410px) {
  .collection-profile-title-wrapper {
    padding: 0.2rem;
  }

  .collection-profile-caption-wrapper {
    min-width: 50%;
  }
}

.total-volume-color {
  color: #cac9c9;
  font-weight: 500;
  font-size: 1rem;
}

.margin-right {
  margin-right: 5px;
}
