.background-lights {
    z-index: -1;
    border-radius: 50%;
    background-color: var(--banner-img-circles-color);
}

/*=======header-top-section=================*/

/*--background-light---*/

.background-light {
    background-color: var(--banner-img-circles-color);
    position: absolute;
}

/*--header-background---*/

.header-background-light {
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: -1;
    filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
    background-color: var(--background-color);
    padding: 10px 0;
    box-shadow: 0 0 10px #000;
    position: sticky;
    top: 0;
    z-index: 10000;
}


.navigation {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    gap: 2rem;
    justify-content: space-between;

}

.nav-left-wrapper a {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
}



.nav-right-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    gap: 1rem;
}


.nav-list-wrapper {
    width: 40%;
}

.nav-list-wrapper .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}





.nav-right-wrapper .menu li a {
    color: var(--text-color);
    transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
    color: var(--primery-color);
}


.nav-search-and-link-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}


.nav-search-and-link-wrapper form {
    width: 95%;
    height: 40px;
    background-color: transparent;
    border: 1px solid var(--primery-color);
    border-radius: 5px;
    position: relative;
}


.nav-search-and-link-wrapper .search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
    background-color: transparent;
    margin-left: 1rem;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
    position: absolute;
    width: 30px;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
    font-size: 1.1rem;
}



.nav-link-wrapper {
    display: flex;
    align-items: center;
    width: 15%;
    gap: 1rem;
    justify-content: space-between;
}

.shop-btn {
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primery-color);
    border-radius: 5px;
    color: var(--text-second-color);
    font-size: 1.2rem;
    transition: all ease 0.3s;
}

.nav-link-btns:hover {
    background-color: rgb(91, 91, 5);
}



/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
    position: relative;
}

.select-profile {
    height: 36px;
    width: 36px;
    background: var(--primery-color);
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: 1.3rem;
    font-weight: 300;
}


.dropdown-profile-mobile-size-item {
    display: none;
}



.select-profile:hover {
    background: rgb(91, 91, 5);
}



/* 
Rotate styles
(add later in javascript)
*/



.drop-menu-profile {
    list-style: none;
    padding: 0 0.1rem;
    background: #ffffffdd;
    border: 1px solid #353a43;
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    color: #000;
    position: absolute;
    top: 3em;
    top: 100%;
    left: -188%;
    width: 200px;
    text-align: center;
    transform: translateX(-50%);
    opacity: 0;
    display: none;
    transition: 0.2s;
    z-index: 1;
    background-color: var(--background-color);
}


.drop-menu-profile li {
    padding: 0.7em 0.5em;
    margin: 0.3em 0;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1rem;
    background-color: var(--text-color);

}

.drop-menu-profile li a {
    font-size: 1.1rem;
    color: var(--text-second-color);
    font-weight: 500;
}

.drop-menu-profile li:hover {
    background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
    background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/


.drop-menu-profile-open {
    display: block;
}

/*profile-drop-down-btn-end*/


/*---navigation-end----*/
/*===============header=======================*/

.header{
    height: 90vh;
    background-image: linear-gradient(to right ,rgba(0,0,0,.7),rgba(255,255,255,.3)),url(../assets//images/contact-us-header-img.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 73% 99%, 0% 72%);
    position: relative;
}

.header::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 70%;
    bottom: 0;
    background: rgb(241, 196, 15,.5);
    clip-path: polygon(23% 51%, 76% 69%, 100% 53%, 100% 100%, 25% 100%, 0 100%, 0 59%);
    -webkit-clip-path:polygon(23% 51%, 76% 69%, 100% 53%, 100% 100%, 25% 100%, 0 100%, 0 59%);
}

.header-content-wrapper{
    position: absolute;
    width: 33%;
    top: 5%;
    left: 5%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.contact-heading-primary{
    font-size: 3rem;
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 1rem;

}

.header-info-wrapper{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.header-info-logo{
    height: 70px;
    width: 70px;
}

.header-info{
    color: var(--text-color);
    text-shadow: 0 5px 10px var(--primery-color);
    font-size: 1.6rem;
    text-align: left;
}

.header-info-link{
    text-decoration: underline;
    transition: all ease .3s;
}

.header-info-link:hover{
    color: var(--primery-color);
}

/*==========get-in-touch-section===========*/

.get-in-touch-section{
    margin-top: 15rem;
}

.contact-form-wrapper{
    border: 2px solid var(--border-color);
    width: 90%;
    margin: 0 auto;
    border-radius: 1rem;
    height: auto;
    position: relative;
}


.get-in-touch-form{
    width: 70%;
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.heading-tertiary{
    position: absolute;
    top: 0% ;
    left: 50%;
    background-color: var(--background-color);
    color: var(--primery-color);
    transform: translate(-50%,-50%);
    width: 40%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
}

.form-first-last-name-wrapper{
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    justify-content: center;
}

.form-group-title{
    font-size: 1.2rem;
    color: var(--text-color);
    margin-bottom: .5rem;
}

.form-group{
    margin-bottom: 2rem;
}

.form-group-name{
    width: 50%;
}

.input-wrapper{
    width: 100%;
    height: 40px;
    background-color: var(--text-color);
    border-radius: 10px;
    overflow: hidden;
}

.form-input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 1rem;
}

.message-wrapper{
    width: 100%;
    height: 10rem;
}

.message-text-area{
    width: 100%;
    height: 100%;
    border: none;
    padding: none;
    border-radius: 10px;
    padding: 1rem;
    outline: none;
    resize: none;
}

.form-button-wrapper{
    text-align: end;
}

.form-btn{
    border: none;
    outline: none;
    width: 180px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--primery-color);
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    box-shadow: inset rgba(0,0,0,1) 0 0 5px,0 5px 10px rgba(0,0,0,0.2);
    transition: all ease 0.3s;
}

.form-btn:hover{
    background-color: var(--background-color);
    border: 1px solid var(--primery-color);
    color: var(--text-color);
}

/*==========contact-us-section===========*/

.contact-us-section{
    margin-block: 10rem;
    border: 2px solid var(--border-color);
    border-inline: none;
    height: 35rem;
    /* background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(../images/contact-us-img-1.jfif); */
    background-image: linear-gradient(to right ,rgba(0,0,0,.7),rgba(241, 196, 15,0.2)),url(../assets//images//contact-us-img-1.jpg);
    background-position: center;
    background-size: cover;
}

.contact-us-content-wrapper{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;

}

.contanct-us-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    width: 30%;
    height: 80%;
    text-align: center;
    padding: 2rem;
}

.contanct-us-box:not(:last-child){
    border-right: 2px solid var(--border-color);
}

.contanct-us-box-logo{
    width: 100px;
    height: 100px;
    
}

.heading-fifth{
    font-size: 2rem;
    color: var(--primery-color);
    font-weight: 500;
    text-transform: uppercase;
}

.paragraph{
    font-size: .8rem;
    color: var(--text-color);
}

.contact-us-info{
    color: var(--text-color);
    text-shadow: 0 2px 10px var(--primery-color);
    font-size: 1.5rem;
}

.contact-us-info-link{
    text-decoration: underline;
    transition: all ease 0.3s;
}

.contact-us-info-link:hover{
    color: var(--primery-color);
}


/*===============footer=============*/

.footer {
    margin-top: 5rem;
    position: relative;
    z-index: 2000;

}

.footer-wrapper {
    border-top: 20px solid var(--primery-color);
    width: 100%;
    background-color: #212121;
    padding: 1rem;
    box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


.footer-left-wrapper {
    padding: 3rem;
    width: 30%;
}

.social-links-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 3rem;
    flex-wrap: wrap;
}

.social-links-wrapper a {
    color: #b0b0b0;
}


.social-links-wrapper a:hover {
    color: var(--primery-color);
}

.footer-left-wrapper strong {
    display: inline-block;
    font-size: 2rem;
    color: #7E4087;
    letter-spacing: 1px;
    margin-bottom: 2rem;
}

.footer-left-wrapper p {
    max-width: 350px;
    color: var(--text-color);
    font-size: 1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;

}

.footer-right-wrapper {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
}

.footer-right-wrapper ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
    color: var(--primery-color);
}

.footer-right-wrapper ul a {
    color: var(--text-color);
    font-weight: 300;
    font-size: 1.2rem;
    transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
    color: var(--primery-color);
}


/*------making-responsive-------------*/
@media screen and (max-width:1500px){
    .header-content-wrapper{
        gap: .5rem;
    }
} 

@media screen and (max-width:1200px) {


    .container {
        max-width: 1200px;
    }

    .navigation {
        width: 95%;
    }

    .nav-list-wrapper .menu {
        gap: 2rem;
    }

    .header-content-wrapper{
        gap: 0;
    }


    .dropdown-btn {
        min-width: 140px;
        min-height: 30px;
    }


    .footer-right-wrapper,
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-left-wrapper {
        min-width: 300px;
    }

    .footer-right-wrapper ul {
        min-width: 160px;
        margin: 1rem;
    }


}

@media screen and (max-width:1150px){
    .contact-us-section{
        height: auto;
    }
    .contact-us-content-wrapper{
        flex-direction: column;
    }

    .contanct-us-box{
        width: 90%;
    }

    .contanct-us-box:not(:last-child){
        border: none;
        border-bottom: 2px solid var(--border-color);
    }
}



@media screen and (max-width:1100px) {



    .nav-list-wrapper {
        display: none;
    }

    .dropdown-profile-mobile-size-item {
        display: block;
    }

    .nav-right-wrapper {
        justify-content: end;
    }


    .nav-search-and-link-wrapper {
        width: 100%;
    }

    .nav-search-and-link-wrapper form {
        width: 70%;
        margin-left: 1rem;
    }

    .nav-link-wrapper {
        gap: 1rem;
        justify-content: center;
    }
    
    .header-content-wrapper{
        width: 80%;
        top: 30%;
        left: 45%;
        transform: translate(-50%,-50%);
        text-align: center;
        gap: .5rem;
    }

}


@media screen and (max-width:750px){
    .heading-tertiary{
        width: 60%;
    }
    .get-in-touch-form {
        width: 90%;
    }

    .header-info-wrapper {
        align-items: start;
    }
    .heading-primary{
        font-size: 2.5rem;
    }

    .header-info{
        font-size: 1.2rem;
    }
    .header-info-logo{
        width: 40px;
        height: 40px;
    }
} 





@media screen and (max-width:650px) {

    .nav-search-and-link-wrapper form {
        display: none;
    }

    .footer-content-wrapper {
        flex-direction: column;
    }

    .header-content-wrapper{
        gap: 1.5rem;
    }

}


@media screen and (max-width:480px){
    .form-first-last-name-wrapper {
        flex-direction: column;
        gap: 0;
    }
    .form-group-name{
        width: 100%;
    }
}




