

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: var(--background-color);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
}

/*--------background------*/

.background-lights {
  z-index: -1;
  border-radius: 50%;
  background-color: var(--banner-img-circles-color);
}

/*=======header-section=================*/

header {
  position: relative;
}

/*--background-light---*/

.background-light {
  background-color: var(--banner-img-circles-color);
  position: absolute;
}

/*--header-background---*/

.header-background-light {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: -1;
  filter: blur(60px);
}

/*---navigation---*/

.header-top-section {
  background-color: var(--background-color);
  padding: 10px 0;
  box-shadow: 0 0 10px #000;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.navigation {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  gap: 2rem;
  justify-content: space-between;
}

.nav-left-wrapper a {
  color: var(--text-color);
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.nav-right-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 1rem;
}

.nav-list-wrapper {
  width: 40%;
}

.nav-list-wrapper .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

.nav-right-wrapper .menu li a {
  color: var(--text-color);
  transition: all ease 0.2s;
}

.nav-right-wrapper .menu li a:hover {
  color: var(--primery-color);
}

.nav-search-and-link-wrapper {
  width: 60%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.nav-search-and-link-wrapper form {
  width: 95%;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--primery-color);
  border-radius: 5px;
  position: relative;
}

.nav-search-and-link-wrapper .search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 1rem;
  background-color: transparent;
  margin-left: 1rem;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn {
  position: absolute;
  width: 30px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text-color);
}

.nav-search-and-link-wrapper .search-btn i {
  font-size: 1.1rem;
}

.nav-link-wrapper {
  display: flex;
  align-items: center;
  width: 15%;
  gap: 1rem;
  justify-content: space-between;
}

.shop-btn {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primery-color);
  border-radius: 5px;
  color: var(--text-second-color);
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

.nav-link-btns:hover {
  background-color: rgb(91, 91, 5);
}

/*profile-drop-down-btn-end*/

.dropdown-btn-profile {
  position: relative;
}

.select-profile {
  height: 36px;
  width: 36px;
  background: var(--primery-color);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 1.3rem;
  font-weight: 300;
}

.dropdown-profile-mobile-size-item {
  display: none;
}

.select-profile:hover {
  background: rgb(91, 91, 5);
}

/* 
Rotate styles
(add later in javascript)
*/

.drop-menu-profile {
  list-style: none;
  padding: 0 0.1rem;
  background: #ffffffdd;
  border: 1px solid #353a43;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  color: #000;
  position: absolute;
  top: 3em;
  top: 100%;
  left: -188%;
  width: 200px;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
  background-color: var(--background-color);
}

.drop-menu-profile li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--text-color);
}

.drop-menu-profile li a {
  font-size: 1.1rem;
  color: var(--text-second-color);
  font-weight: 500;
}

.drop-menu-profile li:hover {
  background: var(--primery-color);
}

/*
Active styles
(added later in javascript)
*/

.active {
  background: var(--primery-color);
}

/* 
open styles
(added later in javascript)
*/

.drop-menu-profile-open {
  display: block;
}

/*profile-drop-down-btn-end*/

/*---navigation-end----*/

/*=============main===========*/

.main {
  width: 90%;
  margin: 4rem auto 4rem auto;
}

/*======card-section========*/

.create-content-wrapper {
  padding: 2rem;
  width: 80%;
  margin: 0 auto;
  height: auto;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--text-second-color);
}

.create-background-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0;
  animation-timing-function: ease-in-out;
  /* Smooth transition */
  animation-iteration-count: infinite;
  /* Loop infinitely */
}

@keyframes changeBackground1 {
  0%,
  23% {
    opacity: 1;
    /* Ensure the first image is fully visible from the beginning */
  }

  28%,
  100% {
    opacity: 0;
  }
}

@keyframes changeBackground2 {
  0%,
  25% {
    opacity: 0;
  }

  30%,
  100% {
    opacity: 1;
  }
}

@keyframes changeBackground3 {
  0%,
  50% {
    opacity: 0;
  }

  55%,
  100% {
    opacity: 1;
  }
}

@keyframes changeBackground4 {
  0%,
  70% {
    opacity: 0;
  }

  80%,
  100% {
    opacity: 1;
  }
}

.create-background-content.background-img-1 {
  animation-name: changeBackground1;
  animation-duration: 10s;
  /* Total duration for each image cycle */
}

.create-background-content.background-img-2 {
  animation-name: changeBackground2;
  animation-duration: 10s;
  /* Total duration for each image cycle */
}

.create-background-content.background-img-3 {
  animation-name: changeBackground3;
  animation-duration: 10s;
  /* Total duration for each image cycle */
}

.create-background-content.background-img-4 {
  animation-name: changeBackground4;
  animation-duration: 10s;
  /* Total duration for each image cycle */
}

/*=========create-box==========*/

.create-box {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  padding-block: 4rem;
}

.create-box-content {
  width: 70%;
  margin: 0 auto;
  padding: 1rem;
}

.logo-wrapper {
  width: 150px;
  position: absolute;
  top: 0;
  left: 0;
}

.diption-logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heading-primary {
  color: var(--text-color);
  text-align: center;
}

.heading-primary-main {
  font-weight: 600;
  letter-spacing: 0.5px;
  display: block;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.heading-primary-sub {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 0 auto;
}

.create-options-wrapper {
  margin-top: 5rem;
}

.create-collection {
  margin-bottom: 2rem;
}

.create-option {
  background-color: var(--background-color);
  width: 100%;
  padding: 1rem 2rem;
  color: var(--text-color);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.4);
  transition: all ease 0.4s;
}

.create-icon-title-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.create-title-icon {
  font-size: 1rem;
}

.heading-secondary {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.create-text {
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.create-option-icon {
  font-size: 1.5rem;
  color: var(--primery-color);
}

.create-option-link:hover .create-option {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

/*===============footer=============*/

.footer {
  margin-top: 5rem;
  position: relative;
  z-index: 2000;
}

.footer-wrapper {
  border-top: 20px solid var(--primery-color);
  width: 100%;
  background-color: #212121;
  padding: 1rem;
  box-shadow: 0 0 5px #000;
}

.footer-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.footer-left-wrapper {
  padding: 3rem;
  width: 30%;
}

.social-links-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 3rem;
  flex-wrap: wrap;
}

.social-links-wrapper a {
  color: #b0b0b0;
}

.social-links-wrapper a:hover {
  color: var(--primery-color);
}

.footer-left-wrapper strong {
  display: inline-block;
  font-size: 2rem;
  color: #7e4087;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.footer-left-wrapper p {
  max-width: 350px;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.footer-right-wrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.footer-right-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.footer-right-wrapper ul .footer-ul-title {
  color: var(--primery-color);
}

.footer-right-wrapper ul a {
  color: var(--text-color);
  font-weight: 300;
  font-size: 1.2rem;
  transition: all ease 80ms;
}

.footer-right-wrapper ul a:hover {
  color: var(--primery-color);
}

/*------making-responsive-------------*/

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .navigation {
    width: 95%;
  }

  .nav-list-wrapper .menu {
    gap: 2rem;
  }

  .dropdown-btn {
    min-width: 140px;
    min-height: 30px;
  }

  .footer-right-wrapper,
  .footer-wrapper {
    flex-wrap: wrap;
  }

  .footer-left-wrapper {
    min-width: 300px;
  }

  .footer-right-wrapper ul {
    min-width: 160px;
    margin: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .nav-list-wrapper {
    display: none;
  }

  .dropdown-profile-mobile-size-item {
    display: block;
  }

  .nav-right-wrapper {
    justify-content: end;
  }

  .nav-search-and-link-wrapper {
    width: 100%;
  }

  .nav-search-and-link-wrapper form {
    width: 70%;
    margin-left: 1rem;
  }

  .nav-link-wrapper {
    gap: 1rem;
    justify-content: center;
  }

  .create-content-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .create-box-content {
    width: 95%;
  }
}

@media screen and (max-width: 650px) {
  .nav-search-and-link-wrapper form {
    display: none;
  }

  .footer-content-wrapper {
    flex-direction: column;
  }

  .select {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .dropdown-btn {
    width: 130px;
    height: 30px;
  }

  .collection-drop-button-wrapper {
    gap: 2rem;
  }

  .collection-drop-button-section {
    max-width: 100%;
  }

  .collection-drop-button-wrapper {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.6rem;
  }

  .drop-menu {
    background-color: var(--text-second-color);
    color: var(--text-color);
    position: relative;
    padding: 0.1rem;
  }

  .drop-menu li {
    gap: 1px;
  }

  .art-style-drop-menu {
    opacity: 0;
    display: none;
  }

  .logo-wrapper {
    width: 110px;
  }

  .heading-primary-main {
    font-size: 1.5rem;
  }

  .heading-primary-sub {
    font-size: 1rem;
  }

  .create-option-link {
    width: 320px;
    display: block;
    margin: 0 auto;
  }

  .create-option {
    width: 320px;
  }

  .heading-secondary {
    font-size: 1rem;
  }

  .create-text {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 550px) {
  .nav-left-wrapper a {
    font-size: 1.5rem;
  }

  .collection-filters-and-boxes-wrapper {
    flex-direction: column;
  }

  .search-and-sort-wrapper {
    flex-direction: column;
  }

  .create-content-wrapper {
    padding: 1rem;
  }

  .create-box-content {
    width: 100%;
  }

  .create-content-wrapper {
    padding: 0.5rem;
  }

  .main {
    width: 95%;
  }

  .create-option-link {
    width: 250px;
  }

  .create-option {
    padding: 1rem;
    width: 250px;
    height: 80px;
    margin: 0 auto;
  }

  .create-option:first-child {
    margin-bottom: 1rem;
  }

  .create-icon-title-wrapper {
    margin: 0;
  }

  .mobile-size-text-create-text {
    display: none;
  }
}

@media screen and (max-width: 410px) {
  .collection-profile-title-wrapper {
    padding: 0.2rem;
  }

  .collection-profile-caption-wrapper {
    min-width: 50%;
  }
}
